import {
  Email,
  Phone,
  Place,
  Instagram,
  LinkedIn,
  Twitter,
  Facebook,
} from "@mui/icons-material";

import {
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import { StyledTextField } from "../../custom/styled/styledInputs";
import {
  useDeleteContactMutation,
  useGetContactQuery,
} from "./companyApiSlice";
import AddContactModal from "./AddContactModal";
import CustomConfirmation from "../../custom/CustomConfirmation";

function Contact({ setToast }) {
  const { data: contactData, isLoading: isLoadingContactData } =
    useGetContactQuery();
  const [deleteContact, { isLoading: isDeletingContact }] =
    useDeleteContactMutation();

  const contactTypes = [
    "phone",
    "address",
    "direction",
    "email",
    "linkedin",
    "twitter",
    "instagram",
    "facebook",
  ];
  const [selectedType, setSelectedType] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [targetContact, setTargetContact] = useState(null);
  const [createContactAnchorEl, setcreateContactAnchorEl] = useState(null);
  const [contactAnchorEl, setContactAnchorEl] = useState(null);

  const openCreateMenu = Boolean(createContactAnchorEl);
  const openContactMenu = Boolean(contactAnchorEl);

  const handleClickCreate = (event) => {
    setcreateContactAnchorEl(event.currentTarget);
  };

  const handleClickContact = (event, contact) => {
    setTargetContact(contact);
    setContactAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTargetContact(null);
    setcreateContactAnchorEl(null);
    setContactAnchorEl(null);
    setOpenAddContact(false);
  };

  const handleDeleteContact = async (contact) => {
    try {
      const response = await deleteContact(contact).unwrap();
      setToast({
        status: "success",
        message: response.message || "Contact deleted successfully",
      });
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete contact" });
      // console.log(error);
    }
  };

  const ContactIcon = (type) => {
    return type === "phone" ? (
      <Phone />
    ) : type === "email" ? (
      <Email />
    ) : type === "address" ? (
      <Place />
    ) : type === "linkedin" ? (
      <LinkedIn />
    ) : type === "facebook" ? (
      <Facebook />
    ) : type === "twitter" ? (
      <Twitter />
    ) : type === "instagram" ? (
      <Instagram />
    ) : (
      ""
    );
  };

  return (
    <Box
      sx={{
        "& .MuiInputBase-input": {
          border: "none",
        },
        "& .css-i4bv87-MuiSvgIcon-root": {
          color: "primary.main",
        },
      }}
    >
      {/* -------- Modals ------------ */}
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <AddContactModal
        openAddContact={openAddContact}
        targetContact={targetContact}
        setToast={setToast}
        handleClose={handleClose}
        selectedType={selectedType}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "36px",
            lineHeight: "54px",
          }}
        >
          Contact
        </Typography>
        <CustomLoadingButton onClick={(e) => handleClickCreate(e)}>
          Add
        </CustomLoadingButton>
        <Menu
          anchorEl={createContactAnchorEl}
          open={openCreateMenu}
          onClose={handleClose}
        >
          {contactTypes.map((type, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setSelectedType(type);
                setOpenAddContact(true);
              }}
            >
              {type}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Grid container spacing={3}>
        {contactData?.contact?.map((contact) => (
          <Grid item xs={12} md={6} key={contact.id}>
            <FormControl
              fullWidth
              onClick={(e) => handleClickContact(e, contact)}
            >
              <Typography variant="inputLabel"> {contact.type}</Typography>
              <StyledTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {ContactIcon(contact.type)}
                    </InputAdornment>
                  ),
                }}
                disabled={true}
                value={contact.value}
              />
            </FormControl>
          </Grid>
        ))}
        <Menu
          anchorEl={contactAnchorEl}
          open={openContactMenu}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setOpenAddContact(true);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() =>
              setConfirmation({
                message: "Are you sure you want to delete this item?",
                action: () => {
                  handleDeleteContact(targetContact);
                  handleClose();
                },
              })
            }
          >
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Box>
  );
}

export default Contact;
