import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Career"],
});

const careerApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllCareer: builder.query({
      query: () => `/career`,
      providesTags: ["Career"],
    }),
    getCareer: builder.query({
      query: (parameter) => `/career/${parameter.id}`,
      providesTags: ["Career"],
    }),
    createCareer: builder.mutation({
      query: (values) => ({
        url: "/career",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Career"],
    }),
    updateCareer: builder.mutation({
      query: (values) => ({
        url: `/career/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Career"],
    }),
    deleteCareer: builder.mutation({
      query: (value) => ({
        url: `/career/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Career"],
    }),
  }),
});

export const {
  useGetAllCareerQuery,
  useGetCareerQuery,
  useCreateCareerMutation,
  useUpdateCareerMutation,
  useDeleteCareerMutation,
} = careerApiSlice;
