import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logo.png";
import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as MediaLibraryIcon } from "../../assets/media.svg";
import { ReactComponent as NewsIcon } from "../../assets/news.svg";
import { ReactComponent as SegmentIcon } from "../../assets/segment.svg";
import { ReactComponent as CompanyProfileIcon } from "../../assets/profile.svg";
import { ReactComponent as PortfolioIcon } from "../../assets/portfolio.svg";
import { ReactComponent as CareerOpeningsIcon } from "../../assets/career.svg";
import { ReactComponent as ReferencesIcon } from "../../assets/references.svg";
import { ReactComponent as Logout } from "../../assets/logout.svg";
import { SvgIcon } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { StyledFilledButton } from "../custom/styled/styledButtons";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../app/apiSlice";
import { logout } from "../pages/auth/authSlice";
import { PagesOutlined, InfoOutlined } from "@mui/icons-material";

function SideNav(props) {
  const { drawerWidth, mobileOpen, handleDrawerToggle } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const website = process.env.REACT_APP_WEBSITE;

  const handleLogout = () => {
    dispatch(logout());
    dispatch(apiSlice.util.resetApiState());
    navigate("/login");
  };

  const navItems = [
    { text: "Dashboard", link: "/", icon: DashboardIcon },
    { text: "Media", link: "/media-library", icon: MediaLibraryIcon },
    { text: "News", link: "/news", icon: NewsIcon },
    { text: "Segment", link: "/segment", icon: SegmentIcon },
    {
      text: "Company",
      link: "/company-profile",
      icon: CompanyProfileIcon,
    },
    { text: "Portfolio", link: "portfolio", icon: PortfolioIcon },
    {
      text: "Career",
      link: "/career-openings",
      icon: CareerOpeningsIcon,
    },
    { text: "Reference", link: "references", icon: ReferencesIcon },
    {
      text: "Page",
      link: "/pages",
      icon: PagesOutlined,
    },
    {
      text: "Meta",
      link: "/meta",
      icon: InfoOutlined,
    },
  ];

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        paddingBottom: "20px",
      }}
    >
      <Box sx={{ width: "240px", py: 4 }}>
        <a href={website} target="_blank" rel="noreferrer">
          <img src={logo} alt="logo" width={240} />
        </a>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        component="ul"
        sx={{
          listStyleType: "none",
          width: "240px",
          pl: 0,
          "& a": {
            color: "grey.dark",
            textDecoration: "none",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            gap: 3,
            my: 2,
            p: 1,
          },
        }}
      >
        {navItems.map((item, i) => (
          <Box component="li" key={i}>
            <NavLink
              end
              to={item.link}
              style={({ isActive }) => ({
                backgroundColor: isActive ? "#F1EEEE" : "",
              })}
            >
              <SvgIcon
                component={item.icon}
                inheritViewBox
                height={21}
                width={21}
              />
              <Typography
                sx={{ fontWeight: 600, fontSize: "20px", lineHeight: "33px" }}
              >
                {item.text}
              </Typography>
            </NavLink>
          </Box>
        ))}
      </Box>

      <StyledFilledButton
        startIcon={
          <SvgIcon
            component={Logout}
            inheritViewBox
            sx={{ height: "26px", width: "26px" }}
          />
        }
        onClick={handleLogout}
      >
        Logout
      </StyledFilledButton>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideNav.propTypes = {
  drawerWidth: PropTypes.number,
  handleDrawerToggle: PropTypes.func,
  mobileOpen: PropTypes.bool,
};

export default SideNav;
