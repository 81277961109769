import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Media"],
});

const mediaApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: ({ type, page }) => `/media/${type}?page=${page}`,
      providesTags: ["Media"],
    }),
    addMedia: builder.mutation({
      query: (values) => ({
        url: "/media",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Media"],
    }),
    deleteMedia: builder.mutation({
      query: (value) => ({
        url: `/media/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Media"],
    }),
  }),
});

export const { useGetMediaQuery, useAddMediaMutation, useDeleteMediaMutation } =
  mediaApiSlice;
