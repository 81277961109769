import { AddCircle } from "@mui/icons-material";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import CustomInnerSideNav from "../../custom/CustomInnerSideNav";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";
import { StyledWhiteButton } from "../../custom/styled/styledButtons";
import AddSegmentModal from "./AddSegmentModal";
import {
  useDeleteSegmentBlockMutation,
  useDeleteSegmentMutation,
  useGetAllSegmentsQuery,
  useUpdateSegmentMutation,
} from "./segmentApiSlice";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import { StyledTextField } from "../../custom/styled/styledInputs";
function Segment() {
  const [title, setTitle] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [openAddSegment, setOpenAddSegment] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);

  const [updateSegment, { isLoading: isUpdatingSegment }] =
    useUpdateSegmentMutation();
  const { data: segments, isLoading: isLoadingSegments } =
    useGetAllSegmentsQuery();
  const [deleteBlock] = useDeleteSegmentBlockMutation();
  const [deleteSegment] = useDeleteSegmentMutation();

  const tabs = segments?.segments?.map((segment) => segment.name);
  const activeSegment = segments?.segments[activeTab];

  useEffect(() => {
    setTitle(activeSegment?.name || "");
    setBlocks(
      activeSegment?.blocks?.map((block) =>
        block.type === "image"
          ? // make the media  array editable
            { ...block, media: block.media?.map((media) => media) }
          : block
      ) || []
    );
  }, [activeTab, segments]);

  const handleSave = async () => {
    const body = {
      id: activeSegment?.id,
      body: {
        name: title,
        blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
      },
    };
    try {
      const response = await updateSegment(body);
      console.log("update segent", response);
      setToast({ status: "success", message: "segment updated successfully" });
    } catch (error) {
      setToast({ status: "success", message: "error updating segment" });
      console.log(error);
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  const handleDeleteTab = async (tabIndex) => {
    try {
      let tabItem = segments?.segments[tabIndex];
      const response = await deleteSegment(tabItem).unwrap();
      setToast({ status: "success", message: "Segment deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete segment" });
      console.log(error);
    }
  };

  return (
    <Box>
      {/* dialogs */}
      <AddSegmentModal
        openAddSegment={openAddSegment}
        handleClose={() => setOpenAddSegment(false)}
        setToast={setToast}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomAppBar>
        <Typography variant="pageHeading">Segment</Typography>
        <CustomLoadingButton loading={isUpdatingSegment} onClick={handleSave}>
          Save
        </CustomLoadingButton>
      </CustomAppBar>
      <Box className="page-body">
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={3}>
            <CustomInnerSideNav
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleDelete={(tabIndex) =>
                setConfirmation({
                  message:
                    "are you sure you want to delete this  and its content?!",
                  action: () => handleDeleteTab(tabIndex),
                })
              }
            >
              <StyledWhiteButton
                startIcon={<AddCircle />}
                onClick={() => setOpenAddSegment(true)}
              >
                Add Segment
              </StyledWhiteButton>
            </CustomInnerSideNav>
          </Grid>
          <Grid item xs={8}>
            {isLoadingSegments ? (
              <CustomLoadingCircles />
            ) : (
              <>
                <Box
                  sx={{
                    backgroundColor: "grey.light",
                    marginBottom: "16px",
                    padding: 2,
                    borderRadius: "8px",
                  }}
                >
                  <FormControl fullWidth>
                    <Typography variant="inputLabel" sx={{ mb: 1 }}>
                      Title
                    </Typography>
                    <StyledTextField
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>
                </Box>
                <CustomBlocksEditor
                  blocks={blocks}
                  setBlocks={setBlocks}
                  deleteBlock={handleDeleteBlock}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Segment;
