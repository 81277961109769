import React, { useState } from "react";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { MoreHoriz, MoreVert } from "@mui/icons-material";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import { useDeleteMetaMutation, useGetMetaQuery } from "./metaApiSlice";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import CustomToast from "../../custom/CustomToast";
import Truncate from "react-truncate-html";

function MetaView({
  setOpenEditor,
  setActiveMeta,
  setSelectedType,
  metaTypes,
}) {
  const { data: metaList, isLoading: isLoadingMeta } = useGetMetaQuery();
  const [deleteMeta, { isLoading: isDeletingMeta }] = useDeleteMetaMutation();
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createMetaAnchorEl, setCreateMetaAnchorEl] = useState(null);
  const [targetMeta, setTargetMeta] = useState(null);
  const open = Boolean(anchorEl);
  const openCreateMenu = Boolean(createMetaAnchorEl);

  const handleClick = (event, meta) => {
    setAnchorEl(event.currentTarget);
    setTargetMeta(meta);
  };

  const handleClickCreate = (event) => {
    setCreateMetaAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTargetMeta(null);
    setCreateMetaAnchorEl(null);
  };

  const handleDeleteMeta = async (meta) => {
    try {
      await deleteMeta(meta).unwrap();
      setToast({ status: "success", message: "meta deleted successfully" });
    } catch (error) {
      setToast({
        status: "error",
        message: error?.data?.message || "error deleting meta",
      });
      console.log(error);
    }
  };

  return (
    <Box>
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomAppBar>
        <Typography variant="pageHeading">Meta</Typography>
        <StyledFilledButton onClick={(e) => handleClickCreate(e)}>
          Create
        </StyledFilledButton>
        <Menu
          id="basic-menu"
          anchorEl={createMetaAnchorEl}
          open={openCreateMenu}
          onClose={handleClose}
        >
          {metaTypes.map((type, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                if (metaList?.metas?.map((meta) => meta.type).includes(type)) {
                  handleClose();
                  alert("meta of that type already exists");
                } else {
                  setSelectedType(type);
                  setOpenEditor(true);
                }
              }}
            >
              {type}
            </MenuItem>
          ))}
        </Menu>
      </CustomAppBar>
      {isLoadingMeta ? (
        <Box className="page-body">
          <CustomLoadingCircles />
        </Box>
      ) : (
        <Box className="page-body">
          {metaList?.metas?.map((metaItem) => (
            <Box
              key={metaItem.id}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "grey.light",
                flexWrap: "wrap",
                borderRadius: "10px",
                padding: "8px 16px",
                mb: 2,
                "& .content-box": {
                  flexGrow: 1,
                  color: "grey.dark",
                  "& .header": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    "& .title": {
                      fontWeight: 600,
                      fontSize: "20px",
                      lineHeight: "35px",
                    },
                    "& .published": {
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "24px",
                      "& > span": {
                        fontStyle: "italic",
                        fontWeight: 600,
                      },
                    },
                  },
                  "& .description": {
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "30px",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  },
                },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={11.5}>
                  <Box className="content-box">
                    <Box className="header">
                      <Typography className="title">
                        {metaItem.title}
                      </Typography>
                      <Typography className="published">
                        Type:
                        <span>{metaItem.type}</span>
                      </Typography>
                    </Box>
                    <Truncate
                      lines={3}
                      dangerouslySetInnerHTML={{
                        __html: metaItem.blocks?.find(
                          (block) => block.type === "text"
                        )?.content,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={0.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <MoreVert
                      sx={{
                        display: { xs: "none", md: "block" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, metaItem)}
                    />
                    <MoreHoriz
                      sx={{
                        display: { xs: "block", md: "none" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, metaItem)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                setActiveMeta(targetMeta);
                setOpenEditor(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                setConfirmation({
                  message: "Are you sure you want to delete this item?",
                  action: () => {
                    handleDeleteMeta(targetMeta);
                    handleClose();
                  },
                })
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}

export default MetaView;
