import {
  CheckBox,
  PictureAsPdf,
  PlayCircleFilled,
  SaveAlt,
} from "@mui/icons-material";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import {
  useAddMediaMutation,
  useGetMediaQuery,
} from "../pages/media-library/mediaApiSlice";
import CustomModal from "./CustomModal";
import { StyledFilledButton } from "./styled/styledButtons";
import { useRef } from "react";
import { useState } from "react";
import CustomLoadingCircles from "./CustomLoadingCircles";
import { format, parseISO } from "date-fns";
import ImageKit from "../../helpers/ImageKit";

function CustomFilePicker({
  open,
  handleClose,
  setToast,
  selectedFile,
  setSelectedFile,
  handleClickDone,
}) {
  const [page, setPage] = useState(1);
  const uploadButtonRef = useRef(null);

  let fileType =
    open === "thumbnail" ? "image" : open === "brochure" ? "pdf" : open;

  const { data: media, isFetching: isFetchingMedia } = useGetMediaQuery({
    type: `type/${fileType}`,
    page,
  });

  const [addMedia, { isLoading: uploadingMedia }] = useAddMediaMutation();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const uploadFile = async (data) => {
    try {
      const response = await addMedia(data).unwrap();
      setSelectedFile(response?.media[0]?.url); // automatically select file
      setToast({ status: "success", message: "Media uploaded successfully" });
    } catch (error) {
      setToast({ status: "error", message: "Failed to upload media" });
      console.log(error);
    }
  };

  const handleImagePickerChange = function (e) {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      const data = new FormData();
      data.append("file", file);
      uploadFile(data);
    }
  };

  return (
    <CustomModal open={!!open} handleClose={handleClose}>
      <Box className="page-body" sx={{ p: 2 }}>
        {/* header  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexWrap: "wrap",
            mb: 3,
            gap: 3,
          }}
        >
          <Typography variant="h5">Pick {open} from library</Typography>

          <StyledFilledButton
            sx={{ height: "40px" }}
            onClick={() => uploadButtonRef.current.click()}
          >
            {uploadingMedia ? "Uploading..." : "Upload external"}
          </StyledFilledButton>
          <input
            style={{ display: "none" }}
            type="file"
            ref={uploadButtonRef}
            onChange={handleImagePickerChange}
            accept={
              open === "pdf"
                ? ".pdf"
                : open === "thumbanil"
                ? "image/*"
                : open === "video"
                ? "video/*"
                : ""
            }
          />
        </Box>
        {/* end of header  */}
        {isFetchingMedia ? (
          <CustomLoadingCircles />
        ) : (
          <Grid container rowSpacing={3} columnSpacing={2}>
            {media?.media?.map((item, i) => (
              <Grid item key={i} xs={4} sm={4} md={3} lg={3} xl={2}>
                <Box
                  onClick={() => setSelectedFile(item.url)}
                  sx={{
                    "& .media-card": {
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "black",
                      "& .icon": {
                        color: "white",
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                      },
                    },
                  }}
                >
                  {open === "thumbnail" && (
                    <img
                      src={ImageKit({ url: item.url, thumbnail: true })}
                      alt=""
                      height={80}
                      width={"100%"}
                    />
                  )}
                  {open === "video" && (
                    <Box className="media-card">
                      <PlayCircleFilled
                        // onClick={() => setCurrentVideo(item)}
                        className="icon"
                      />
                    </Box>
                  )}
                  {item.type === "pdf" && (
                    <Box
                      className="media-card"
                      // component="a"
                      // href={item.url}
                      // download
                    >
                      <PictureAsPdf className="icon" />
                    </Box>
                  )}
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "30px",
                      color: "grey.dark",
                    }}
                  >
                    {item.id} - {format(parseISO(item.updated_at), "yy/MM/dd")}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {selectedFile === item.url && (
                      <CheckBox
                        sx={{
                          color: "green",
                          height: "18px",
                          width: "18px",
                          cursor: "pointer",
                        }}
                        onClick={() => console.log("check", item)}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Pagination
            count={media?.pagination?.pages}
            page={page}
            onChange={handleChangePage}
          />
          <StyledFilledButton sx={{ height: "40px" }} onClick={handleClickDone}>
            Done
          </StyledFilledButton>
        </Box>
      </Box>
    </CustomModal>
  );
}

export default CustomFilePicker;
