import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import { Box, Dialog, Typography } from "@mui/material";
import { useEffect } from "react";

function CustomToast({ toast, setToast }) {
  const handleClose = () => {
    setToast(null);
  };

  setTimeout(() => {
    // autoclose toast
    handleClose();
  }, 3000);

  return (
    <Dialog open={!!toast} onClose={handleClose} sx={{ borderRadius: 0 }}>
      <Box
        sx={{
          minWidth: "200px",
          backgroundColor: "#F5F5F5",
          textAlign: "center",
          p: 5,
          "& .icon": {
            height: "40px",
            width: "40px",
            color: "primary.main",
          },
        }}
      >
        {toast?.status === "success" && <CheckCircleOutline className="icon" />}
        {toast?.status === "error" && <HighlightOff className="icon" />}
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "30px",
            my: 2,
          }}
        >
          {toast?.message}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default CustomToast;
