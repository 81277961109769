import React, { useState } from "react";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { ArrowDropDown, More, MoreHoriz, MoreVert } from "@mui/icons-material";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import { useDeleteNewsMutation, useGetNewsQuery } from "./newsApiSlice";
import { format, parseISO } from "date-fns";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import CustomToast from "../../custom/CustomToast";
import Truncate from "react-truncate-html";
import ImageKit from "../../../helpers/ImageKit";

function NewsView({ setOpenEditor, setActiveNews }) {
  const { data: newsList, isLoading: isLoadingNews } = useGetNewsQuery();
  const [deleteNews, { isLoading: isDeletingNews }] = useDeleteNewsMutation();
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetNews, setTargetNews] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, news) => {
    setAnchorEl(event.currentTarget);
    setTargetNews(news);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTargetNews(null);
  };

  const handleDeleteNews = async (news) => {
    try {
      await deleteNews(news).unwrap();
      setToast({ status: "success", message: "news deleted successfully" });
    } catch (error) {
      setToast({
        status: "error",
        message: error?.data?.message || "error deleting news",
      });
      console.log(error);
    }
  };

  const countReaders = (news) => {
    let uniqueIPs = [];
    news?.views?.map((view) => {
      if (!uniqueIPs.includes(view.ip)) {
        uniqueIPs.push(view.ip);
      }
    });
    return uniqueIPs.length;
  };


  return (
    <Box>
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomAppBar>
        <Typography variant="pageHeading">News</Typography>
        <StyledFilledButton onClick={() => setOpenEditor(true)}>
          Create
        </StyledFilledButton>
      </CustomAppBar>
      {isLoadingNews ? (
        <Box className="page-body">
          <CustomLoadingCircles />
        </Box>
      ) : (
        <Box className="page-body">
          {newsList?.news.map((newsItem) => (
            <Box
              key={newsItem.id}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "grey.light",
                flexWrap: "wrap",
                borderRadius: "10px",
                padding: "8px 16px",
                mb: 2,
                "& .content-box": {
                  flexGrow: 1,
                  color: "grey.dark",
                  "& .header": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    "& .title": {
                      fontWeight: 600,
                      fontSize: "30px",
                      lineHeight: "45px",
                    },
                    "& .published": {
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "24px",
                      "& > span": {
                        fontStyle: "italic",
                      },
                    },
                  },
                  "& .description": {
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "30px",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  },
                  "& .percent-box": {
                    display: "flex",
                    alignItems: "center",
                    "& .rise-icon": {
                      color: " #05812F",
                      fontSize: "34px",
                    },
                    "& .drop-icon": {
                      color: "#FF0000",
                      fontSize: "34px",
                    },
                    "& .text": {
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                    },
                  },
                },

                "& .counts": {
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  color: "black",
                  p: 1,
                  gap: 2,
                  "& .title": {
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                  },
                  "& .count": {
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "36px",
                  },
                },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={2.5}>
                  <img
                    alt=""
                    src={ImageKit({
                      url: newsItem.featured_image,
                      thumbnail: true,
                    })}
                    width={"100%"}
                    height={170}
                    style={{ borderRadius: "10px" }}
                  />
                </Grid>

                <Grid item xs={12} md={7.7}>
                  <Box className="content-box">
                    <Box className="header">
                      <Typography className="title">
                        {newsItem.title}
                      </Typography>
                      <Typography className="published">
                        published:{" "}
                        <span>
                          {newsItem.published_at
                            ? format(
                                parseISO(newsItem.published_at),
                                "dd MMM yyyy"
                              )
                            : ""}
                        </span>
                      </Typography>
                    </Box>
                    {/* <Box
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: newsItem.blocks?.find(
                        (block) => block.type === "text"
                      )?.content,
                    }}
                  /> */}
                    <Truncate
                      lines={3}
                      dangerouslySetInnerHTML={{
                        __html: newsItem.blocks?.find(
                          (block) => block.type === "text"
                        )?.content,
                      }}
                    />
                    {/* <Box className="percent-box ">
                      <ArrowDropDown className="drop-icon" />
                      <Typography className="text">% Last 30days</Typography>
                    </Box> */}
                  </Box>
                </Grid>

                <Grid item container spacing={1} xs={12} md={1.3}>
                  <Grid item xs={12}>
                    <Box className="counts">
                      <Typography className="title">New Readers</Typography>
                      <Typography className="count">
                        {countReaders(newsItem)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="counts">
                      <Typography className="title">Views</Typography>
                      <Typography className="count">
                        {newsItem.views?.length}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={0.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <MoreVert
                      sx={{
                        display: { xs: "none", md: "block" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, newsItem)}
                    />
                    <MoreHoriz
                      sx={{
                        display: { xs: "block", md: "none" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, newsItem)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setActiveNews(targetNews);
                setOpenEditor(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                setConfirmation({
                  message: "Are you sure you want to delete this item?",
                  action: () => {
                    handleDeleteNews(targetNews);
                    handleClose();
                  },
                })
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}

export default NewsView;
