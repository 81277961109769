import { Box, FormControl, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import CustomModal from "../../custom/CustomModal";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import { StyledTextField } from "../../custom/styled/styledInputs";
import { useCreatePortfolioMutation } from "./portfolioApiSlice";

function AddPortfolioModal({ open, handleClose, setToast }) {
  const [name, setName] = useState("");
  const [createSector, { isLoading: isCreatingSector }] =
    useCreatePortfolioMutation();

  const handleSubmit = async () => {
    try {
      if (!name) {
        return setToast({ status: "error", message: "name cannot be empty" });
      }
      const body = {
        name,
        blocks: [],
      };
      const response = await createSector(body).unwrap();
      setToast({
        status: "success",
        message: response.message || "Sector created succesfully",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      setToast({ status: "error", message: "error creating sector" });
    }
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Box
        sx={{
          my: 3,
          mx: 8,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          gap: 5,
        }}
      >
        <FormControl>
          <Typography variant="inputLabel">Sector Name</Typography>
          <StyledTextField
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <CustomLoadingButton loading={isCreatingSector} onClick={handleSubmit}>
          Add
        </CustomLoadingButton>
      </Box>
    </CustomModal>
  );
}

export default AddPortfolioModal;
