import { PictureAsPdf, PlayCircleFilled } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import React, { useState } from "react";
import { useEffect } from "react";
import ImageKit from "../../../helpers/ImageKit";
import CustomFilePicker from "../../custom/CustomFilePicker";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import CustomVideoPlayer from "../../custom/CustomVideoPlayer";
import {
  useCreateDocumentMutation,
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
} from "./companyApiSlice";

function Documents({ setToast }) {
  const [openFilePicker, setOpenFilePicker] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(""); //to select the url
  const [selectedVideoThumbnail, setSelectedVideoThumbnail] = useState(""); //to select the url
  const [selectedBrochure, setSelectedBrochure] = useState(""); //to select the url
  const [loadingButton, setLoadingButton] = useState(""); // button to activate loading for
  const [currentVideo, setCurrentVideo] = useState(null); // to play video

  const [existingVideo, setExistingVideo] = useState(null); //for update
  const [existingBrochure, setExistingBrochure] = useState(null); // for update

  const { data } = useGetDocumentsQuery();
  const [createDocument, { isLoading: isCreatingDocument }] =
    useCreateDocumentMutation();
  const [updateDocument, { isLoading: isUpdatingDocument }] =
    useUpdateDocumentMutation();

  const documents = data?.documents;

  useEffect(() => {
    setExistingBrochure(
      documents?.find((doc) => doc.type === "brochure") || null
    );
    setExistingVideo(documents?.find((doc) => doc.type === "video") || null);
  }, [documents]);

  const handleSaveDocument = (type) => {
    setLoadingButton(type);
    let body;

    if (type === "brochure") {
      body = {
        type: "brochure",
        url: selectedBrochure || existingBrochure || "",
      };
      existingBrochure
        ? handleUpdate({ body, id: existingBrochure.id })
        : handleCreate(body);
    } else if (type === "video" || type === "thumbnail") {
      body = {
        type: "video",
        url: selectedVideo || existingVideo?.url || "",
        thumbnail: selectedVideoThumbnail || existingVideo?.thumbnail || "",
      };
      existingVideo
        ? handleUpdate({ body, id: existingVideo.id })
        : handleCreate(body);
    }
  };

  const handleCreate = async (body) => {
    try {
      await createDocument(body).unwrap();
      setToast({
        status: "success",
        message: "document created  successfully",
      });
    } catch (error) {
      setToast({
        status: "error",
        message: "failed to save document",
      });
      console.log(error);
    }
  };

  const handleUpdate = async (body) => {
    try {
      await updateDocument(body).unwrap();
      setToast({
        status: "success",
        message: "document updated  successfully",
      });
    } catch (error) {
      setToast({
        status: "error",
        message: "failed to save document",
      });
      console.log(error);
    }
  };

  return (
    <Box>
      {/* Modals */}
      <CustomFilePicker
        open={openFilePicker}
        handleClose={() => setOpenFilePicker(null)}
        handleClickDone={() => {
          handleSaveDocument(openFilePicker);
          setOpenFilePicker(null);
        }}
        selectedFile={
          openFilePicker === "video"
            ? selectedVideo
            : openFilePicker === "thumbnail"
            ? selectedVideoThumbnail
            : openFilePicker === "brochure"
            ? selectedBrochure
            : ""
        }
        setSelectedFile={
          openFilePicker === "video"
            ? setSelectedVideo
            : openFilePicker === "thumbnail"
            ? setSelectedVideoThumbnail
            : openFilePicker === "brochure"
            ? setSelectedBrochure
            : ""
        }
      />

      <CustomVideoPlayer
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
      />

      {/* end of modals */}

      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "36px",
          lineHeight: "54px",
        }}
      >
        Documents
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          "& .item-row": {
            display: "flex",
            alignItems: "center",
            gap: 3,
            flexWrap: "wrap",
          },
          "& .media-card": {
            height: "150px",
            width: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "black",
            "& .icon": {
              color: "white",
              height: "50px",
              width: "50px",
              cursor: "pointer",
            },
          },
        }}
      >
        <h3>Video</h3>
        <Box className="item-row">
          <img
            src={ImageKit({
              url: existingVideo?.thumbnail || "",
              thumbnail: true,
            })}
            alt=""
            height={150}
            width={300}
          />
          <CustomLoadingButton
            onClick={() => setOpenFilePicker("thumbnail")}
            loading={
              (isCreatingDocument || isUpdatingDocument) &&
              loadingButton === "thumbnail"
            }
          >
            Choose
          </CustomLoadingButton>
        </Box>
        <Box className="item-row">
          <Box className="media-card">
            <PlayCircleFilled
              onClick={() =>
                setCurrentVideo({
                  url: existingVideo?.url || "",
                })
              }
              className="icon"
            />
          </Box>
          <CustomLoadingButton
            onClick={() => setOpenFilePicker("video")}
            loading={
              (isCreatingDocument || isUpdatingDocument) &&
              loadingButton === "video"
            }
          >
            Choose
          </CustomLoadingButton>
        </Box>

        <h3>Brochure</h3>
        <Box className="item-row">
          <a className="media-card" href={existingBrochure?.url || ""}>
            <PictureAsPdf className="icon" />
          </a>
          <CustomLoadingButton
            onClick={() => setOpenFilePicker("brochure")}
            loading={
              (isCreatingDocument || isUpdatingDocument) &&
              loadingButton === "brochure"
            }
          >
            Choose
          </CustomLoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Documents;
