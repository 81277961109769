import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Culture", "Contact", "About", "Team", "Document"],
});

const companyApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCulture: builder.query({
      query: () => `/company/culture`,
      providesTags: ["Culture"],
    }),
    getContact: builder.query({
      query: () => `/company/contact`,
      providesTags: ["Contact"],
    }),
    getAbout: builder.query({
      query: () => `/company/about`,
      providesTags: ["About"],
    }),
    getTeam: builder.query({
      query: () => `/company/team`,
      providesTags: ["Team"],
    }),
    getDocuments: builder.query({
      query: () => `/company/documents`,
      providesTags: ["Document"],
    }),
    createCulture: builder.mutation({
      query: (values) => ({
        url: "/company/culture",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Culture"],
    }),
    createAbout: builder.mutation({
      query: (values) => ({
        url: "/company/about",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["About"],
    }),
    createTeamMember: builder.mutation({
      query: (values) => ({
        url: "/company/team",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Team"],
    }),
    createDocument: builder.mutation({
      query: (values) => ({
        url: "/company/document",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Document"],
    }),
    updateDocument: builder.mutation({
      query: (values) => ({
        url: `/company/document/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Document"],
    }),
    updateTeamMember: builder.mutation({
      query: (values) => ({
        url: `/company/team/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Team"],
    }),
    createContact: builder.mutation({
      query: (values) => ({
        url: "/company/contact",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Contact"],
    }),
    updateContact: builder.mutation({
      query: (values) => ({
        url: `/company/contact/${values.id}`,
        method: "PATCH",
        body: values.body,
      }),
      invalidatesTags: ["Contact"],
    }),
    deleteContact: builder.mutation({
      query: (value) => ({
        url: `/company/contact/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contact"],
    }),
    deleteTeam: builder.mutation({
      query: (value) => ({
        url: `/company/team/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Team"],
    }),
    deleteCultureBlock: builder.mutation({
      query: (value) => ({
        url: `/company/culture/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Culture"],
    }),
    deleteAboutBlock: builder.mutation({
      query: (value) => ({
        url: `/company/about/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["about"],
    }),
  }),
});

export const {
  useGetAboutQuery,
  useGetCultureQuery,
  useGetContactQuery,
  useGetTeamQuery,
  useGetDocumentsQuery,
  useCreateAboutMutation,
  useCreateContactMutation,
  useCreateCultureMutation,
  useCreateTeamMemberMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useUpdateTeamMemberMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useDeleteTeamMutation,
  useDeleteCultureBlockMutation,
  useDeleteAboutBlockMutation,
} = companyApiSlice;
