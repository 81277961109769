import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function Chart({ trends }) {
  let annualTrends = trends?.map((item) => ({
    ...item,
    monthIndex: new Date(item.date).getMonth(),
  }));

  const data = {
    labels,
    datasets: [
      {
        data: labels.map(
          (label, i) =>
            annualTrends?.find((item) => item.monthIndex === i)?.count || 0
        ),
        fill: true,
        borderColor: "#603229",
        backgroundColor: "#603229",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}

export default Chart;
