import { Button, styled } from "@mui/material";

export const StyledFilledButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid transparent",
  padding: "1px 22px",
  fontSize: "28px",
  fontWeight: "600",
  lineHeight: "42px",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "10px",
  minWidth: "10px",
  textTransform: "none",

  "&:hover": {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

export const StyledGreyButton = styled(Button)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.32)",
  backgroundColor: theme.palette.grey.light,
  border: "1px solid transparent",
  padding: "1px 22px",
  fontSize: "22px",
  fontWeight: "600",
  lineHeight: "42px",
  textAlign: "center",
  whiteSpace: "wrap",
  borderRadius: "10px",
  minWidth: "10px",
  textTransform: "none",

  "&:hover": {},
}));
export const StyledWhiteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: "white",
  border: `0.4px solid ${theme.palette.primary.main}`,
  padding: "6px 16px",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "10px",
  minWidth: "10px",
  textTransform: "none",

  "&:hover": {},
}));
