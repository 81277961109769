import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";
import MetaView from "./MetaView";
import MetaCreate from "./MetaCreate";

function Meta() {
  const [openEditor, setOpenEditor] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [activeMeta, setActiveMeta] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const handleCloseEditor = () => {
    setOpenEditor(false);
    setActiveMeta(null);
  };

  const metaTypes = [
    "about",
    "segment",
    "reference",
    "portfolio",
    "career",
    "culture",
  ];

  return (
    <Box>
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {openEditor ? (
        <MetaCreate
          handleCloseEditor={handleCloseEditor}
          setToast={setToast}
          activeMeta={activeMeta}
          selectedType={selectedType}
          setConfirmation={setConfirmation}
        />
      ) : (
        <MetaView
          setOpenEditor={setOpenEditor}
          setActiveMeta={setActiveMeta}
          setSelectedType={setSelectedType}
          metaTypes={metaTypes}
        />
      )}
    </Box>
  );
}

export default Meta;
