import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Portfolio"],
});

const PortfolioApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllPortfolio: builder.query({
      query: () => `/portfolio`,
      providesTags: ["Portfolio"],
    }),
    getPortfolio: builder.query({
      query: (parameter) => `/portfolio/${parameter.id}`,
      providesTags: ["Portfolio"],
    }),
    createPortfolio: builder.mutation({
      query: (values) => ({
        url: "/portfolio",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Portfolio"],
    }),
    updatePortfolio: builder.mutation({
      query: (values) => ({
        url: `/portfolio/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Portfolio"],
    }),
    deletePortfolio: builder.mutation({
      query: (value) => ({
        url: `/portfolio/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Portfolio"],
    }),
    deletePortfolioBlock: builder.mutation({
      query: (value) => ({
        url: `/portfolio/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Portfolio"],
    }),
  }),
});

export const {
  useGetAllPortfolioQuery,
  useGetPortfolioQuery,
  useCreatePortfolioMutation,
  useUpdatePortfolioMutation,
  useDeletePortfolioBlockMutation,
  useDeletePortfolioMutation,
} = PortfolioApiSlice;
