import { CheckBox } from "@mui/icons-material";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import {
  useAddMediaMutation,
  useGetMediaQuery,
} from "../pages/media-library/mediaApiSlice";
import CustomModal from "./CustomModal";
import ImageKit from "../../helpers/ImageKit";
import { StyledFilledButton } from "./styled/styledButtons";
import { useRef } from "react";
import { useState } from "react";
import CustomLoadingCircles from "./CustomLoadingCircles";

function CustomImagePicker({
  open,
  handleClose,
  blocks,
  addOrDropImage,
  setToast,
}) {
  const [page, setPage] = useState(1);
  const uploadButtonRef = useRef(null);

  const { data: media, isFetching: isFetchingMedia } = useGetMediaQuery({
    type: "type/image",
    page,
  });

  const [addMedia, { isLoading: uploadingMedia }] = useAddMediaMutation();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const togglePickImage = (image) => {
    addOrDropImage(image, open.index);
  };

  const uploadFiles = async (data) => {
    try {
      const response = await addMedia(data).unwrap();
      response.media?.forEach((media) => {
        addOrDropImage({ url: media.url, id: media.id }, open.index); //automatically select files
      });
      setToast({ status: "success", message: "Media uploaded successfully" });
    } catch (error) {
      setToast({ status: "error", message: "Failed to upload media" });
      console.log(error);
    }
  };

  const handleImagePickerChange = function (e) {
    let selectedFiles;
    const data = new FormData();
    if (e.target.files) {
      selectedFiles = [...e.target.files];
      selectedFiles?.forEach((file) => {
        data.append("file", file);
      });
      uploadFiles(data);
    }
  };

  return (
    <CustomModal open={!!open} handleClose={handleClose}>
      <Box className="page-body" sx={{ p: 2 }}>
        {/* header  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexWrap: "wrap",
            mb: 3,
          }}
        >
          <Typography variant="h5">Pick Image(s)</Typography>
          <Typography variant="h5">
            {blocks?.[open?.index]?.media?.length || 0}/3
          </Typography>
          <StyledFilledButton
            sx={{ height: "40px" }}
            onClick={() => uploadButtonRef.current.click()}
          >
            {uploadingMedia ? "Uploading..." : "Upload external"}
          </StyledFilledButton>
          <input
            style={{ display: "none" }}
            type="file"
            ref={uploadButtonRef}
            onChange={handleImagePickerChange}
            accept="image/*"
            multiple
          />
        </Box>
        {/* end of header  */}
        {isFetchingMedia ? (
          <CustomLoadingCircles />
        ) : (
          <Grid container rowSpacing={3} columnSpacing={2}>
            {media?.media?.map((item, i) => (
              <Grid item key={i} xs={4} sm={4} md={3} lg={3} xl={2}>
                <Box
                  sx={{
                    "& img": {
                      height: "120px",
                      width: "100%",
                      cursor: "pointer",
                    },
                  }}
                >
                  <img
                    src={ImageKit({ url: item.url, thumbnail: true })}
                    alt=""
                    height={181}
                    width={"100%"}
                    onClick={() => togglePickImage(item)}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "20px",
                      lineHeight: "30px",
                      color: "grey.dark",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "grey.dark",
                      }}
                    >
                      {item.resolution}, {item.size} {item.type}
                    </Typography>
                    {blocks?.[open?.index]?.media?.find(
                      (media) => media.id === item.id
                    ) && (
                      <CheckBox
                        sx={{
                          color: "green",
                          height: "18px",
                          width: "18px",
                          cursor: "pointer",
                        }}
                        onClick={() => console.log("check", item)}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Pagination
            count={media?.pagination?.pages}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Box>
    </CustomModal>
  );
}

export default CustomImagePicker;
