import { Close } from "@mui/icons-material";
import { Box, Dialog } from "@mui/material";
import React from "react";

function CustomModal({ children, handleClose, open }) {
  return (
    <Dialog open={open} onClose={handleClose} sx={{ borderRadius: 0 }}>
      <Box
        sx={{
          minWidth: "200px",
          backgroundColor: "rgb(183, 183, 183, 0.8)",
          "& .close": {
            display: "flex",
            justifyContent: "flex-end",
          },
        }}
      >
        <Box className="close">
          <Close
            onClick={handleClose}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              cursor: "pointer",
            }}
          />
        </Box>
        {children}
      </Box>
    </Dialog>
  );
}

export default CustomModal;
