import React, { useEffect, useState } from "react";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import {
  useDeleteCultureBlockMutation,
  useGetCultureQuery,
} from "./companyApiSlice";

function Culture({ blocks, setBlocks, setToast }) {
  const { data: culture, isLoading } = useGetCultureQuery();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeleteCultureBlockMutation();

  useEffect(() => {
    if (culture?.culture) {
      setBlocks(
        culture?.culture?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
    }
  }, [culture]);

  const handleDeleteBlock = async (block) => {
    console.log("delete", block);
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <CustomLoadingCircles />
      ) : culture ? (
        <CustomBlocksEditor
          blocks={blocks}
          setBlocks={setBlocks}
          deleteBlock={handleDeleteBlock}
        />
      ) : (
        <h6>unable to load company culture, try again.</h6>
      )}
    </div>
  );
}

export default Culture;
