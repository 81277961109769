import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomModal from "../../custom/CustomModal";
import {
  StyledSelect,
  StyledTextArea,
  StyledTextField,
} from "../../custom/styled/styledInputs";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import {
  useCreateTeamMemberMutation,
  useUpdateTeamMemberMutation,
} from "./companyApiSlice";
import { useGetAllSegmentsQuery } from "../segment/segmentApiSlice";
import { useAddMediaMutation } from "../media-library/mediaApiSlice";
import CustomTextEditor from "../../custom/CustomTextEditor";

function AddTeamMemberModal({
  openAddMember,
  handleClose,
  setToast,
  targetMember,
}) {
  const [createTeamMember, { isLoading: isAddingTeamMember }] =
    useCreateTeamMemberMutation();
  const [updateTeamMember, { isLoading: isUpdatingMember }] =
    useUpdateTeamMemberMutation();
  const [uploadMedia, { isLoading: isUploadingMedia }] = useAddMediaMutation();

  const { data: segments } = useGetAllSegmentsQuery();
  const formik = useFormik({
    initialValues: {
      title: targetMember?.title || "",
      name: targetMember?.name || "",
      segment: targetMember?.segment?.id || "",
      picture: targetMember?.image?.id || "",
      position: targetMember?.position || "",
      social: targetMember?.social || "",
      bio: targetMember?.bio || "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      title: yup.string().required("required"),
      name: yup.string().required("required"),
      segment: yup.string().required("required"),
      // picture: yup.string().required("required"),
      position: yup.string().required("required"),
      social: yup.string().required("required"),
      bio: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const data = {
        title: values.title,
        name: values.name,
        segment_id: values.segment,
        image_id: values.picture,
        position: values.position,
        social: values.social,
        bio: values.bio,
      };

      if (targetMember) {
        handleUpdateTeamMember(data);
      } else {
        handleCreateTeamMember(data);
      }
    },
  });

  console.log(targetMember);

  const handleImagePickerChange = function (e) {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      const validFormats = [
        "image/webp",
        "image/png",
        "image/jpeg",
        "image/png",
      ];

      const isValid = validFormats.includes(file.type);
      if (!isValid) {
        return alert("invalid file format");
      } // end of validation
      const data = new FormData();
      data.append("file", file);
      uploadFile(data);
    }
  };

  const uploadFile = async (data) => {
    try {
      const response = await uploadMedia(data).unwrap();
      setToast({ status: "success", message: "Media uploaded successfully" });
      formik.setFieldValue("picture", response?.media[0]?.id);
    } catch (error) {
      setToast({ status: "error", message: "Failed to upload media" });
      console.log(error);
    }
  };

  const handleCreateTeamMember = async (data) => {
    try {
      const response = await createTeamMember(data);
      setToast({
        status: "success",
        message: response.message || "member created successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateTeamMember = async (data) => {
    // implement update here
    const body = { body: data, id: targetMember?.id };
    try {
      const response = await updateTeamMember(body);
      setToast({
        status: "success",
        message: response.message || "member updated successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomModal open={openAddMember} handleClose={handleClose}>
      <Box
        sx={{
          m: 5,
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Title</Typography>
              <StyledSelect
                displayEmpty
                name="title"
                id="title"
                onChange={formik.handleChange}
                value={formik.values.title}
              >
                <MenuItem value="" disabled>
                  <em>Select title</em>
                </MenuItem>
                <MenuItem value="MR">Mr.</MenuItem>
                <MenuItem value="MRS">Mrs.</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
              </StyledSelect>
              <FormHelperText variant="error">
                {formik.touched.title && formik.errors.title}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Name</Typography>
              <StyledTextField
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <FormHelperText variant="error">
                {formik.touched.name && formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth>
              <Typography variant="inputLabel"> Segment</Typography>
              <StyledSelect
                displayEmpty
                name="segment"
                id="segment"
                onChange={formik.handleChange}
                value={formik.values.segment}
              >
                <MenuItem value="" disabled>
                  <em>Select segment</em>
                </MenuItem>
                {segments?.segments?.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </StyledSelect>
              <FormHelperText variant="error">
                {formik.touched.segment && formik.errors.segment}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Picture</Typography>
              <StyledTextField type="file" onChange={handleImagePickerChange} />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Position</Typography>
              <StyledTextField
                name="position"
                id="position"
                value={formik.values.position}
                onChange={formik.handleChange}
              />
              <FormHelperText variant="error">
                {formik.touched.position && formik.errors.position}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Social</Typography>
              <StyledTextField
                name="social"
                id="social"
                value={formik.values.social}
                onChange={formik.handleChange}
              />
              <FormHelperText variant="error">
                {formik.touched.social && formik.errors.social}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Bio</Typography>
              <CustomTextEditor
                value={formik.values.bio}
                onChange={(content) => {
                  formik.setFieldValue("bio", content);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CustomLoadingButton
              sx={{ mt: 3 }}
              type="submit"
              loading={isAddingTeamMember || isUpdatingMember}
            >
              {targetMember ? "Update" : "Add"}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
}

export default AddTeamMemberModal;
