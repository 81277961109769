import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Pages"],
});

const pagesApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query({
      query: () => `/pages`,
      providesTags: ["Pages"],
    }),
    createPage: builder.mutation({
      query: (values) => ({
        url: "/page",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Pages"],
    }),
    updatePage: builder.mutation({
      query: (values) => ({
        url: `/page/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Pages"],
    }),
    deletePage: builder.mutation({
      query: (value) => ({
        url: `/page/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Pages"],
    }),
    deletePageBlock: builder.mutation({
      query: (value) => ({
        url: `/page/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Page"],
    }),
  }),
});

export const {
  useGetPagesQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageBlockMutation,
  useDeletePageMutation,
} = pagesApiSlice;
