import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import CustomAppBar from "../../custom/CustomAppBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as Badge } from "../../../assets/one-badge.svg";
import { StyledDashboardCard } from "../../custom/styled/styledpapers";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useGetDataTrendsQuery } from "./dashboardApiSlice";
import { format } from "date-fns";
import CustomDatePicker from "../../custom/CustomDatePicker";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import Chart from "./Chart";
import ImageKit from "../../../helpers/ImageKit";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const { data, isLoading, error } = useGetDataTrendsQuery({
    date: format(selectedDate, "yyyy-MM-dd"),
  });
  const user = useSelector(selectCurrentUser);

  console.log("data", data);

  const calculateChange = (previous, current) => {
    if (previous === 0 && current === 0) {
      return 0;
    }
    if (current > previous) {
      let change = ((current - previous) / current) * 100;
      return change.toFixed(2);
    } else {
      let change = ((previous - current) / previous) * 100;
      return change.toFixed(2);
    }
  };

  return (
    <div>
      <CustomDatePicker
        open={openDatePicker}
        setOpen={setOpenDatePicker}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <CustomAppBar>
        <Box>
          <Typography variant="pageHeading">Welcome {user?.name}</Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "18px", lineHeight: "27px" }}
          >
            {format(new Date(), "eeee dd, MMMM yyyy")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "318px",
            height: "64px",
            background: "#FFFFFF",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenDatePicker(true);
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "22px",
              lineHeight: "33px",
            }}
          >
            {format(selectedDate, "dd - MMMM yyyy")}
          </Typography>
          <KeyboardArrowDownIcon />
        </Box>
      </CustomAppBar>
      {/* beginning of main content */}
      {isLoading ? (
        <Box height="100vh">
          <CustomLoadingCircles />
        </Box>
      ) : (
        <Grid container columnSpacing={4} rowSpacing={3} alignItems="center">
          <Grid item xs={12} sm={5} md={4} xl={3}>
            <StyledDashboardCard>
              <Typography
                className="card-title-small center-text"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Badge height="24px" width="16px" />
                Most Viewed Post
              </Typography>
              <Divider className="divider" />
              <Typography className="card-sub-title center-text">
                {data?.posts?.top1?.title || "-"}
              </Typography>
              <Typography className="num-count-large center-text">
                {data?.posts?.top1?.count?.toLocaleString() || "0"}
              </Typography>
              <Box className="percent-box center-text">
                {data?.posts?.current_week.count >
                data?.posts?.previous_week.count ? (
                  <ArrowDropUp className="rise-icon" />
                ) : (
                  <ArrowDropDown className="drop-icon" />
                )}
                <Typography className="text">
                  {calculateChange(
                    data?.posts?.previous_week.count,
                    data?.posts?.current_week.count
                  )}
                  %
                </Typography>
              </Box>
            </StyledDashboardCard>
          </Grid>
          <Grid item xs={12} sm={5} md={4} xl={3}>
            <StyledDashboardCard>
              <Typography className="card-title-small center-text">
                Landing Page
              </Typography>
              <Divider className="divider" />
              <Typography className="card-sub-title center-text">
                VIEWS
              </Typography>
              <Typography className="num-count-large center-text">
                {data?.landing_page?.all_time?.count?.toLocaleString() || "0"}
              </Typography>
              <Box className="percent-box center-text">
                {data?.landing_page?.current_week.count >
                data?.landing_page?.previous_week.count ? (
                  <ArrowDropUp className="rise-icon" />
                ) : (
                  <ArrowDropDown className="drop-icon" />
                )}
                <Typography className="text">
                  {calculateChange(
                    data?.landing_page?.previous_week.count,
                    data?.landing_page?.current_week.count
                  )}
                  %
                </Typography>
              </Box>
            </StyledDashboardCard>
          </Grid>
          <Grid item xs={12} sm={0} md={4} xl={6}></Grid>
          {/* end of first row */}
          <Grid item xs={12} md={4}>
            <StyledDashboardCard sx={{ px: 3 }}>
              <Typography className="card-title-small">
                Visitors this week
              </Typography>
              <Box className="flex-spaceBetween">
                <Typography className="num-count-large center-text">
                  {data?.users?.current_week?.count?.toLocaleString() || "0"}
                </Typography>
                <Box className="percent-box center-text">
                  {data?.users?.current_week.count >
                  data?.users?.previous_week.count ? (
                    <ArrowDropUp className="rise-icon" />
                  ) : (
                    <ArrowDropDown className="drop-icon" />
                  )}
                  <Typography className="text">
                    {calculateChange(
                      data?.users?.previous_week.count,
                      data?.users?.current_week.count
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
              <Divider className="divider" />
              <Typography className="card-title-small">
                Visitors this month
              </Typography>
              <Box className="flex-spaceBetween">
                <Typography className="num-count-large center-text">
                  {data?.users?.current_month?.count?.toLocaleString() || "0"}
                </Typography>
                <Box className="percent-box center-text">
                  {data?.users?.current_month.count >
                  data?.users?.previous_month.count ? (
                    <ArrowDropUp className="rise-icon" />
                  ) : (
                    <ArrowDropDown className="drop-icon" />
                  )}
                  <Typography className="text">
                    {calculateChange(
                      data?.users?.previous_month.count,
                      data?.users?.current_month.count
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
            </StyledDashboardCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledDashboardCard>
              <Typography className="card-title-big center-text">
                Popular Project
              </Typography>
              <Divider className="divider" />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  mt: 2,
                  mb: 1.2,
                }}
              >
                <Box
                  component="img"
                  src={ImageKit({
                    url: data?.projects?.top1?.image,
                    thumbnail: true,
                  })}
                  alt=""
                  sx={{
                    height: "60px",
                    width: "60px",
                    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
                    borderRadius: "21px",
                  }}
                />

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "29px",
                      mb: 1,
                    }}
                  >
                    {data?.projects?.top1?.title || ""}
                  </Typography>
                  {/* <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
                  -
                </Typography> */}
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      mb: 1,
                    }}
                  >
                    Last week
                  </Typography>
                  <Typography className="num-count-small">
                    {data?.projects?.previous_week?.count?.toLocaleString() ||
                      "0"}
                  </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      mb: 1,
                    }}
                  >
                    Current week
                  </Typography>
                  <Typography className="num-count-small">
                    {data?.projects?.current_week?.count?.toLocaleString() ||
                      "0"}
                  </Typography>
                </Box>{" "}
              </Box>
            </StyledDashboardCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledDashboardCard sx={{ p: 0, pt: "8px", overflow: "hidden" }}>
              <Typography className="card-title-big center-text">
                Visitors Trend
              </Typography>
              <Divider className="divider" />
              <Typography className="num-count-small" sx={{ ml: 2 }}>
                {data?.trends?.total?.count?.toLocaleString() || "0"}
              </Typography>
              <Chart trends={data?.trends?.monthly_view_trends} />
            </StyledDashboardCard>
          </Grid>
          {/* end of second row */}
          <Grid item xs={12}>
            <StyledDashboardCard sx={{ p: 3 }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "32px",
                  lineHeight: "48px",
                  mb: 2,
                }}
              >
                Popular Posts
              </Typography>
              {data?.posts.top5?.map((item, i) => (
                <Box className="top5-item flex-spaceBetween" mb={1}>
                  <Box className="flex">
                    <Typography className="title">{i + 1}.</Typography>
                    <Box
                      component="img"
                      src={ImageKit({
                        url: item?.image,
                        thumbnail: true,
                      })}
                      alt=""
                      sx={{
                        height: "60px",
                        width: "60px",
                        filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
                        borderRadius: "21px",
                      }}
                    />
                    <Typography className="title">{item.title}</Typography>
                  </Box>
                  {/* <Box className="percent-box center-text">
                  <ArrowDropDown className="drop-icon" />
                  <Typography className="text">1.17%</Typography>
                </Box> */}
                  <Box className="flex">
                    Views
                    <Typography className="num-count-large">
                      {item.count?.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </StyledDashboardCard>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Dashboard;
