import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Segment"],
});

const segmentApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllSegments: builder.query({
      query: () => `/segment`,
      providesTags: ["Segment"],
    }),
    getSegment: builder.query({
      query: (parameter) => `/segment/${parameter.id}`,
      providesTags: ["Segment"],
    }),
    createSegment: builder.mutation({
      query: (values) => ({
        url: "/segment",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Segment"],
    }),
    updateSegment: builder.mutation({
      query: (values) => ({
        url: `/segment/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Segment"],
    }),
    deleteSegment: builder.mutation({
      query: (value) => ({
        url: `/segment/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Segment"],
    }),
    deleteSegmentBlock: builder.mutation({
      query: (value) => ({
        url: `/segment/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Segment"],
    }),
  }),
});

export const {
  useGetAllSegmentsQuery,
  useGetSegmentQuery,
  useCreateSegmentMutation,
  useUpdateSegmentMutation,
  useDeleteSegmentMutation,
  useDeleteSegmentBlockMutation,
} = segmentApiSlice;
