import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../../assets/logo-large.png";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import { useLoginMutation } from "./authApiSlice";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useState } from "react";

function Login() {
  const [login, { isLoading, error }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().email("must be a valid email").required("required"),
      password: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleLogin = async (values) => {
    try {
      const userData = await login(values).unwrap();
      dispatch(setCredentials(userData));
      localStorage.setItem("credentials", JSON.stringify(userData));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  console.log(error);

  return (
    <Box>
      <Grid container>
        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "grey.light",
              height: "100vh",
              "& img": {
                margin: "0 20px",
                width: "80%",
              },
            }}
          >
            <img src={logo} alt="logo" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              gap: 5,
              "& .header": {
                fontWeight: 500,
                fontSize: "60px",
                lineHeight: "90px",
                display: { xs: "none", sm: "block" },
              },
              "& .logo": {
                display: { xs: "block", sm: "none" },
                maxHeight: "70px",
                maxWidth: "300px",
              },
              "& .inputs": {
                display: "flex",
                flexDirection: "column",
                width: "80%",
              },
              "& .input": {
                m: 1,
              },
              "& .forgot-password": {
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "33px",
                textAlign: "right",
                color: "primary.main",
                cursor: "pointer",
              },
              "& .remember-me": {
                ml: "1px",
              },
            }}
          >
            <Typography className="header">Login</Typography>
            <img src={logo} alt="logo" className="logo" />

            <Box className="inputs">
              <FormControl className="input" variant="standard">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  fullWidth
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <FormHelperText variant="error">
                  {formik.touched.email && formik.errors.email}
                </FormHelperText>
              </FormControl>
              <FormControl className="input" variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  fullWidth
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={toggleShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.password && formik.errors.password}
                </FormHelperText>
                {error && (
                  <FormHelperText variant="error">
                    {error?.data?.message}
                  </FormHelperText>
                )}
                <Typography className="forgot-password">
                  Forgot password?
                </Typography>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  className="remember-me"
                  control={<Checkbox defaultChecked />}
                  label="remember me"
                />
                <Typography>Recover Password</Typography>
              </Box>
            </Box>
            <CustomLoadingButton type="submit" loading={isLoading}>
              Login
            </CustomLoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
