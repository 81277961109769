import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomSearch from "../../custom/CustomSearch";
import { MoreHoriz, MoreVert, Tune } from "@mui/icons-material";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import {
  useDeleteReferenceMutation,
  useGetReferenceQuery,
  useUpdateReferenceMutation,
} from "./referenceApiSlice";
import { useGetAllPortfolioQuery } from "../portfolio/portfolioApiSlice";
import Truncate from "react-truncate-html";
import ImageKit from "../../../helpers/ImageKit";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import { StyledTextField } from "../../custom/styled/styledInputs";
import { Stack } from "@mui/system";

function ReferencesList({
  setOpenEditor,
  setActiveReference,
  setToast,
  setConfirmation,
}) {
  const { data: referencesData, isLoading: isLoadingReferences } =
    useGetReferenceQuery();
  const [deleteReference, { isLoading: isDeletingReference }] =
    useDeleteReferenceMutation();
  const { data: portfolios } = useGetAllPortfolioQuery();
  const [updateReference, { isLoading: isUpdatingReference }] =
    useUpdateReferenceMutation();

  const references = referencesData?.references;
  const [heading, setHeading] = useState("");
  const [openEditHeading, setOpenEditHeading] = useState(false);
  const [filteredReferences, setFilteredReferences] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");

  //data to render
  const renderList =
    selectedSector || selectedStatus || searchKey
      ? filteredReferences
      : references;

  //menu states
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetReference, setTargetReference] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, reference) => {
    setAnchorEl(event.currentTarget);
    setTargetReference(reference);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTargetReference(null);
  };

  useEffect(() => {
    let filtered = [];

    filtered.push(
      ...(references?.filter((item) => {
        let search =
          searchKey !== ""
            ? item.title.toLowerCase().search(searchKey?.toLowerCase()) !== -1
            : true;
        let status =
          selectedStatus !== ""
            ? item.status.toLowerCase() === selectedStatus?.toLowerCase()
            : true;
        let sector =
          selectedSector !== ""
            ? item.portfolio_id === parseInt(selectedSector)
            : true;

        return search && status && sector;
      }) || [])
    );

    setFilteredReferences([...filtered]);
  }, [references, searchKey, selectedSector, selectedStatus]);

  const handleSaveHeading = async () => {
    console.log({ "heading saved": heading });
    setOpenEditHeading(false);
  };

  const handleDeleteReference = async (news) => {
    try {
      await deleteReference(news).unwrap();
      setToast({
        status: "success",
        message: "Reference deleted successfully",
      });
    } catch (error) {
      setToast({
        status: "error",
        message: error?.data?.message || "error deleting reference",
      });
      console.log(error);
    }
  };

  const handleToggleReferenceStatus = async (data) => {
    try {
      handleClose();
      const body = {
        body: {
          ...data,
          status:
            data.status.toLowerCase() === "ongoing" ? "Completed" : "Ongoing",
        },
        id: targetReference?.id,
      };
      const response = await updateReference(body).unwrap();
      setToast({
        status: "success",
        message: "Reference updated successfully",
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      setToast({
        status: "error",
        message: error.data.message || "Error updating reference",
      });
    }
  };

  return (
    <Box>
      <CustomAppBar>
        <Typography variant="pageHeading">References</Typography>
        <StyledFilledButton onClick={() => setOpenEditor(true)}>
          Add
        </StyledFilledButton>
      </CustomAppBar>

      <Box className="page-body">
        <Box
          //-----filter bar component
          sx={{
            display: "flex",
            alignItems: "flex-end",
            mb: 4,
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Select
              displayEmpty
              value={selectedSector}
              onChange={(e) => setSelectedSector(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Tune />
                </InputAdornment>
              }
              sx={{ height: "45px", minWidth: 150 }}
            >
              <MenuItem value="">All</MenuItem>
              {portfolios?.portfolios?.map((sector) => (
                <MenuItem value={sector.id} key={sector.id}>
                  {sector.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              displayEmpty
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Tune />
                </InputAdornment>
              }
              sx={{ height: "45px", minWidth: 150 }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Ongoing">Ongoing</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </Box>
          <CustomSearch
            placeholder="search..."
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </Box>

        {/* <Box
          sx={{
            backgroundColor: "grey.light",
            marginBottom: "16px",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <FormControl fullWidth>
            <Typography variant="inputLabel" sx={{ mb: 1 }}>
              Heading
            </Typography>
            <Stack direction="row" alignItems={"center"} gap={2}>
              {openEditHeading ? (
                <StyledTextField
                  sx={{ flex: 1 }}
                  size="small"
                  name="heading"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
              ) : (
                <Typography sx={{ flex: 1 }}>
                  A selection of successfully executed and ongoing projects
                </Typography>
              )}
              {openEditHeading ? (
                <>
                  <StyledFilledButton
                    onClick={() => {
                      setOpenEditHeading(false);
                      setHeading("");
                    }}
                  >
                    Cancel
                  </StyledFilledButton>
                  <StyledFilledButton onClick={handleSaveHeading}>
                    Save
                  </StyledFilledButton>
                </>
              ) : (
                <StyledFilledButton onClick={() => setOpenEditHeading(true)}>
                  Edit
                </StyledFilledButton>
              )}
            </Stack>
          </FormControl>
        </Box> */}

        {isLoadingReferences ? (
          <CustomLoadingCircles />
        ) : renderList?.length === 0 ? (
          <h5>No result found</h5>
        ) : (
          renderList?.map((reference) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "grey.light",
                flexWrap: "wrap",
                borderRadius: "10px",
                padding: "16px",
                mb: 2,
                "& .content-box": {
                  flexGrow: 1,
                  color: "grey.dark",
                  "& .header": {
                    fontWeight: 700,
                    fontSize: "28px",
                    lineHeight: "42px",
                    "& > span": {
                      fontWeight: 400,
                      fontSize: "24px",
                    },
                  },
                  "& .description": {
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "27px",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  },
                  "& .subtitle": {
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "30px",
                    "& > .green": {
                      fontWeight: "550",
                      color: "green",
                    },
                    "& > .red": {
                      fontWeight: "500",
                      color: "red",
                    },
                    "& > .yellow": {
                      fontWeight: "500",
                      color: "gold",
                    },
                  },
                },
              }}
              key={reference.id}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={2}>
                  <img
                    alt=""
                    src={ImageKit({
                      url: reference.featured_image || "",
                      thumbnail: true,
                    })}
                    width={"100%"}
                    height={172}
                    style={{ borderRadius: "10px" }}
                  />
                </Grid>

                <Grid item xs={12} md={9.5}>
                  <Box className="content-box">
                    <Typography className="header">
                      {reference.title || ""} -{" "}
                      <span>
                        {
                          portfolios?.portfolios?.find(
                            (item) => item.id === reference.portfolio_id
                          )?.name
                        }{" "}
                        sector
                      </span>
                    </Typography>

                    {/* <Box
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: reference.blocks?.find(
                          (block) => block.type === "text"
                        )?.content,
                      }}
                    /> */}

                    <Truncate
                      lines={2}
                      dangerouslySetInnerHTML={{
                        __html: reference.blocks?.find(
                          (block) => block.type === "text"
                        )?.content,
                      }}
                    />

                    <Typography className="subtitle">
                      Location: {reference.location || ""}
                    </Typography>
                    <Typography className="subtitle">
                      status:
                      <span
                        className={
                          reference.status.toLowerCase() === "ongoing"
                            ? "yellow"
                            : "green"
                        }
                      >
                        {reference.status}
                      </span>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={0.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <MoreVert
                      sx={{
                        display: { xs: "none", md: "block" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, reference)}
                    />
                    <MoreHoriz
                      sx={{
                        display: { xs: "block", md: "none" },
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleClick(e, reference)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setActiveReference(targetReference);
              setOpenEditor(true);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() =>
              setConfirmation({
                message: "Are you sure you want to delete this item?",
                action: () => {
                  handleDeleteReference(targetReference);
                  handleClose();
                },
              })
            }
          >
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => handleToggleReferenceStatus(targetReference)}
          >
            {targetReference?.status.toLowerCase() === "ongoing"
              ? "Complete"
              : "Uncomplete"}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default ReferencesList;
