import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  StyledSelect,
  StyledTextArea,
  StyledTextField,
} from "../../custom/styled/styledInputs";
import CustomModal from "../../custom/CustomModal";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useCreateCareerMutation,
  useUpdateCareerMutation,
} from "./careerApiSlice";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import { useGetAllSegmentsQuery } from "../segment/segmentApiSlice";
import { format, parseISO } from "date-fns";
import CustomTextEditor from "../../custom/CustomTextEditor";

function CreateCareerOpening({ open, handleClose, setToast, targetCareer }) {
  const formik = useFormik({
    initialValues: {
      position: targetCareer?.position || "",
      segment: targetCareer?.segment?.id || "",
      description: targetCareer?.description || "",
      closingDate:
        (targetCareer?.closing_date &&
          format(parseISO(targetCareer?.closing_date), "yyyy-MM-dd")) ||
        "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      position: yup.string().required("required"),
      segment: yup.string().required("required"),
      // description: yup.string().required("required"),
      closingDate: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const data = {
        position: values.position,
        description: values.description,
        closing_date: values.closingDate,
        segment_id: values.segment,
      };

      console.log(data);

      if (targetCareer) {
        handleUpdateCareer(data);
      } else {
        handleCreateCareer(data);
      }
    },
  });

  const [createCareer, { isLoading: isCreatingCareer }] =
    useCreateCareerMutation();
  const [updateCareer, { isLoading: isUpdatingCareer }] =
    useUpdateCareerMutation();
  const { data: segments } = useGetAllSegmentsQuery();

  const handleCreateCareer = async (data) => {
    try {
      const response = await createCareer(data).unwrap();
      setToast({
        status: "success",
        message: response.message || "career opening created successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCareer = async (data) => {
    try {
      const body = { body: data, id: targetCareer?.id };
      const response = await updateCareer(body).unwrap();
      setToast({
        status: "success",
        message: response.message || "career opening updated successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      console.log(error);
      setToast({
        status: "error",
        message: error.data.message || "career opening updated successfully",
      });
    }
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Box
        sx={{
          m: 5,
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Position Name</Typography>
              <StyledTextField
                name="position"
                id="position"
                value={formik.values.position}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Typography variant="inputLabel"> Segment</Typography>
              <StyledSelect
                displayEmpty
                name="segment"
                id="segment"
                onChange={formik.handleChange}
                value={formik.values.segment}
              >
                <MenuItem value="" disabled>
                  <em>Select segment</em>
                </MenuItem>
                {segments?.segments?.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </StyledSelect>
              <FormHelperText variant="error">
                {formik.touched.segment && formik.errors.segment}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">Position Description</Typography>
              <CustomTextEditor
                value={formik.values.description}
                onChange={(content) => {
                  formik.setFieldValue("description", content);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <Typography variant="inputLabel">Closing date</Typography>
              <StyledTextField
                type="date"
                name="closingDate"
                values="closingDate"
                value={formik.values.closingDate}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: "end" }}>
            <CustomLoadingButton
              sx={{ mt: 3 }}
              type="submit"
              loading={isCreatingCareer || isUpdatingCareer}
            >
              {targetCareer ? "Update" : "Add"}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
}

export default CreateCareerOpening;
