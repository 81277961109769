import { Box, Dialog, Stack, Typography } from "@mui/material";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { StyledFilledButton, StyledWhiteButton } from "./styled/styledButtons";
function CustomConfirmation({ confirmation, setConfirmation }) {
  const handleClose = () => {
    setConfirmation(null);
  };
  return (
    <Dialog
      open={!!confirmation}
      onClose={handleClose}
      sx={{ borderRadius: 0 }}
    >
      <Box
        sx={{
          minWidth: "200px",
          backgroundColor: "#F5F5F5",
          textAlign: "center",
          p: 5,
        }}
      >
        <WarningIcon />
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "30px",
            my: 2,
          }}
        >
          {confirmation?.message}
        </Typography>
        <Stack gap={3} direction="row">
          <StyledWhiteButton
            onClick={() => {
              confirmation?.action();
              handleClose();
            }}
            sx={{ width: "71px" }}
          >
            Yes
          </StyledWhiteButton>
          <StyledWhiteButton onClick={handleClose} sx={{ width: "71px" }}>
            No
          </StyledWhiteButton>
          <StyledFilledButton onClick={handleClose} sx={{ fontWeight: 500 }}>
            Cancel
          </StyledFilledButton>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default CustomConfirmation;
