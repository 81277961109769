import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";
import PagesCreate from "./PagesCreate";
import PagesList from "./PagesList";

function Pages() {
  const [openEditor, setOpenEditor] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [activePage, setActivePage] = useState(null);

  const handleCloseEditor = () => {
    setOpenEditor(false);
    setActivePage(null);
  };

  return (
    <Box>
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {openEditor ? (
        <PagesCreate
          handleCloseEditor={handleCloseEditor}
          setToast={setToast}
          activePage={activePage}
          setConfirmation={setConfirmation}
        />
      ) : (
        <PagesList
          setOpenEditor={setOpenEditor}
          setActivePage={setActivePage}
        />
      )}
    </Box>
  );
}

export default Pages;
