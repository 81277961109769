import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { More, MoreHoriz, MoreVert } from "@mui/icons-material";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import NewsCreate from "./NewsCreate";
import NewsView from "./NewsView";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";

function News() {
  const [openEditor, setOpenEditor] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [activeNews, setActiveNews] = useState(null);

  const handleCloseEditor = () => {
    setOpenEditor(false);
    setActiveNews(null);
  };

  return (
    <Box>
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {openEditor ? (
        <NewsCreate
          handleCloseEditor={handleCloseEditor}
          setToast={setToast}
          activeNews={activeNews}
          setConfirmation={setConfirmation}
        />
      ) : (
        <NewsView setOpenEditor={setOpenEditor} setActiveNews={setActiveNews} />
      )}
    </Box>
  );
}

export default News;
