import { Box } from "@mui/system";
import React from "react";
import { Circles } from "react-loader-spinner";

function CustomLoadingCircles({ height, width, color }) {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      <Circles
        height={height || "80"}
        width={width || "80"}
        color={color || "#603E38"}
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Box>
  );
}

export default CustomLoadingCircles;
