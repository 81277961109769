import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ImageKit from "../../../helpers/ImageKit";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import AddTeamMemberModal from "./AddTeamMemberModal";
import {
  useDeleteAboutBlockMutation,
  useDeleteTeamMutation,
  useGetAboutQuery,
  useGetTeamQuery,
  useUpdateTeamMemberMutation,
} from "./companyApiSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function About({
  openAddMember,
  setOpenAddMember,
  blocks,
  setBlocks,
  activeTab,
  setActiveTab,
  tabs,
  setToast,
  setConfirmation,
}) {
  const { data: team, isLoading: isLoadingTeam } = useGetTeamQuery();
  const [deleteTeam, { isLoading: isDeletingTeamMember }] =
    useDeleteTeamMutation();
  const { data: about, isLoading: isLoadingAbout } = useGetAboutQuery();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeleteAboutBlockMutation();
  const [updateOrder, { isLoading: isUpdatingMember }] =
    useUpdateTeamMemberMutation();

  // menu states to update team member
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetMember, setTargetMember] = useState(null);

  const [members, setMembers] = useState([]);

  const open = Boolean(anchorEl);
  const handleClickMenu = (event, member) => {
    setAnchorEl(event.currentTarget);
    setTargetMember(member);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTargetMember(null);
    setOpenAddMember(false);
  };

  useEffect(() => {
    // set the about block
    if (about) {
      setBlocks(
        about?.about?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
    }
  }, [about]);

  useEffect(() => {
    setMembers(team?.team);
    // console.log("effect ran");
  }, [team]);

  const handleDeleteBlock = async (block) => {
    // console.log("delete", block);
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      // console.log(error);
    }
  };

  const handleDeleteTeamMember = async (member) => {
    try {
      const response = await deleteTeam(member).unwrap();
      setToast({
        status: "success",
        message: response.message || "Member deleted successfully",
      });
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete member" });
      // console.log(error);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const newMembers = Array.from(members);
    const [reorderedItem] = newMembers.splice(result.source.index, 1);
    const destinationIndex = result.destination.index + 1; //+1 to skip index 0

    // console.log({
    //   member: reorderedItem,
    //   destination: destinationIndex,
    // });

    handleUpdateOrder({ ...reorderedItem, index: destinationIndex });
  };

  const handleUpdateOrder = async (data) => {
    // implement update here
    // console.log("DATA: ", data);
    const body = { body: data, id: data?.id };
    try {
      const response = await updateOrder(body);
      setToast({
        status: "success",
        message: response.message || "order updated successfully",
      });
      // console.log("RES: ", response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        "& .tab-container": {
          display: "flex",
          mb: 2,
        },
        "& .tab-item": {
          fontWeight: 600,
          fontSize: "28px",
          lineHeight: "42px",
          padding: "8px 24px",
          borderRadius: "10px",
          color: "grey.lightText",
          cursor: "pointer",
        },
        "& .active": {
          backgroundColor: "grey.light",
          color: "grey.dark",
        },
      }}
    >
      <Box className="tab-container">
        {tabs?.map((tab, i) => (
          <Typography
            className={`tab-item ${activeTab === i && "active"}`}
            onClick={() => setActiveTab(i)}
          >
            {tab}
          </Typography>
        ))}
      </Box>
      {/* end of tabs header */}

      {activeTab === 0 && (
        //about tab
        <Box>
          {isLoadingAbout ? (
            <CustomLoadingCircles />
          ) : about ? (
            <CustomBlocksEditor
              blocks={blocks}
              setBlocks={setBlocks}
              deleteBlock={handleDeleteBlock}
            />
          ) : (
            <h6>unable to load about company, try again.</h6>
          )}
        </Box>
      )}

      {activeTab === 1 && (
        // team members tab
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="members" direction="vertical">
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  "& .card": {
                    display: "flex",
                    gap: 3,
                    alignItems: "center",
                    backgroundColor: "grey.light",
                    borderRadius: "8px",
                    padding: "8px",
                    mb: 1,
                    "& > img": {
                      borderRadius: "8px",
                    },
                  },
                  "& .detail": {
                    flex: 1,
                    lineHeight: "30px",
                    "& > .name": {
                      fontSize: "20px",
                      fontWeight: 400,
                    },
                    "& > .role": {
                      fontSize: "20px",
                      fontWeight: 600,
                      "& > span": {
                        fontWeight: 400,
                      },
                    },
                  },
                  "& .more-icon": {
                    justifySelf: "flex-end",
                    cursor: "pointer",
                  },
                }}
              >
                {/* dialog */}
                <AddTeamMemberModal
                  openAddMember={openAddMember}
                  handleClose={handleClose}
                  setToast={setToast}
                  targetMember={targetMember}
                />
                {isLoadingTeam ? (
                  <CustomLoadingCircles />
                ) : (
                  members?.map((member, index) => (
                    <Draggable
                      key={member?.id}
                      draggableId={member?.name}
                      isDragDisabled={isLoadingTeam}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="card"
                        >
                          <img
                            src={ImageKit({
                              url: member?.image?.url,
                              thumbnail: true,
                            })}
                            alt="member"
                            height={70}
                            width={70}
                          />
                          <Box className="detail">
                            <Typography className="name">
                              {member?.name}
                            </Typography>
                            <Typography className="role">
                              {member?.position} -{" "}
                              <span>{member?.segment?.name}</span>
                            </Typography>
                          </Box>
                          <MoreVert
                            className="more-icon"
                            onClick={(e) => handleClickMenu(e, member)}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      setTargetMember(targetMember);
                      setOpenAddMember(true);
                    }}
                  >
                    Update
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      setConfirmation({
                        message: "Are you sure you want to delete this member?",
                        action: () => {
                          handleDeleteTeamMember(targetMember);
                          handleClose();
                        },
                      })
                    }
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  );
}

export default About;
