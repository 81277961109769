import {
  TextField,
  Select as MuiSelect,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const StyledTextField = styled(TextField)(({ theme, bold }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "14px",
    fontWeight: bold ? "bold" : "normal",
    lineHeight: "1.42857143",
    color: "#555",
    width: "100%",
    height: "20px",
    padding: "6px 12px",
    transition: theme.transitions.create(["border-color", "background-color"]),
    "&:disabled": {
      backgroundColor: "#eee",
      color: "#888888",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: "1px solid #ccc",
    fontSize: "14px",
    lineHeight: "1.42857143",
    color: "#555",
    width: "100%",
    height: "20px",
    padding: "6px 12px",
    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const StyledMultiSelect = styled(Select)(({ theme, bold }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  "& .css-1s2u09g-control": {
    // container
    padding: "10px",
    backgroundColor: theme.palette.grey.light,
    borderColor: theme.palette.grey.dark,
  },
  "& .css-1pahdxg-control": {
    // container focus
    padding: "10px",
    backgroundColor: theme.palette.grey.light,
    borderColor: theme.palette.grey.dark,
    outline: "none",
  },
  "& .css-1rhbuit-multiValue": {
    // selected value
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "4px",
  },
  "& .css-1okebmr-indicatorSeparator": {
    display: "none",
  },
  "& .css-tlfecz-indicatorContainer": {
    border: `2px solid ${theme.palette.grey.dark}`,
    color: theme.palette.grey.dark,
    padding: 0,
    borderRadius: "50%",
  },
  "& .css-14el2xx-placeholder": {},
}));

export const StyledCreatableMultiSelect = styled(CreatableSelect)(
  ({ theme, bold }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& .css-1s2u09g-control": {
      // container
      padding: "10px",
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.grey.dark,
    },
    "& .css-1pahdxg-control": {
      // container focus
      padding: "10px",
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.grey.dark,
      outline: "none",
    },
    "& .css-1rhbuit-multiValue": {
      // selected value
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "4px",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-tlfecz-indicatorContainer": {
      border: `2px solid ${theme.palette.grey.dark}`,
      color: theme.palette.grey.dark,
      padding: 0,
      borderRadius: "50%",
    },
    "& .css-14el2xx-placeholder": {},
  })
);

export const StyledCreatableSingleSelect = styled(CreatableSelect)(
  ({ theme, bold }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& .css-1s2u09g-control": {
      // container
      backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
      border: "1px solid #ccc",
    },
    "& .css-1pahdxg-control": {
      // container focus
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      // outline: "none",
    },
    "& .css-1rhbuit-multiValue": {
      // selected value
      // backgroundColor: "white",
      // borderRadius: "10px",
      // padding: "4px",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-tlfecz-indicatorContainer": {
      color: theme.palette.grey.dark,
      padding: 2,
    },
    "& .css-14el2xx-placeholder": {},
  })
);

export const StyledFileInput = styled("input")(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
  border: "1px solid #ccc",
  fontSize: "14px",
  lineHeight: "1.42857143",
  color: "#555",
  width: "100%",
  padding: "5px 12px",
  boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),

  "&:focus": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export const StyledTextArea = styled(TextareaAutosize)(({ theme, bold }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
  border: "1px solid #ccc",
  fontSize: "14px",
  fontWeight: bold ? "bold" : "normal",
  lineHeight: "1.42857143",
  color: "#555",
  width: "100%",
  height: "20px",
  padding: "6px 12px",
  transition: theme.transitions.create(["border-color", "background-color"]),

  "&:disabled": {
    backgroundColor: "#eee",
    color: "#888888",
  },

  "&:focus": {
    borderColor: theme.palette.primary.main,
    outlineColor: theme.palette.primary.main,
  },
}));
