import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { StyledGreyButton } from "../../custom/styled/styledButtons";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import {
  StyledCreatableMultiSelect,
  StyledTextField,
} from "../../custom/styled/styledInputs";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import {
  useCreateNewsMutation,
  useDeleteNewsBlockMutation,
  useUpdateNewsMutation,
} from "./newsApiSlice";
import { useAddMediaMutation } from "../media-library/mediaApiSlice";
import ImageKit from "../../../helpers/ImageKit";

function NewsCreate({
  handleCloseEditor,
  setToast,
  activeNews,
  setConfirmation,
}) {
  const [blocks, setBlocks] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [datePublished, setDatePublished] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const filePickerRef = useRef(null);

  const [createNews, { isLoading: isCreatingNews }] = useCreateNewsMutation();
  const [updateNews, { isLoading: isUpdatingNews }] = useUpdateNewsMutation();
  const [uploadMedia, { isLoading: isUploadingImage }] = useAddMediaMutation();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeleteNewsBlockMutation();

  useEffect(() => {
    //fill up initial state for update news
    if (activeNews) {
      setTitle(activeNews.title || "");
      setBlocks(
        // make the blocks array editable
        activeNews?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
      setSelectedCategories(
        activeNews?.categories?.map((cat) => ({
          label: cat.name,
          value: cat.name,
        })) || []
      );
      setSelectedTags(
        activeNews?.tags?.map((tag) => ({
          label: tag.name,
          value: tag.name,
        })) || []
      );
      setDatePublished(activeNews?.datePublished);
      setFeaturedImage(activeNews?.featured_image || "");
    }
  }, [activeNews]);

  const handleSave = () => {
    const body = {
      title: title,
      featured_image: featuredImage,
      categories: selectedCategories?.map((item) => item.value),
      tags: selectedTags?.map((item) => item.value),
      published_at: datePublished,
      blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };

    console.log("body", body);
    if (!title) {
      return setToast({ status: "error", message: "News title is required" });
    }

    if (activeNews) {
      handleUpdateNews(body);
    } else {
      handleCreateNews(body);
    }
  };

  const handleCreateNews = async (body) => {
    try {
      const response = await createNews(body).unwrap();
      handleCloseEditor();
      setToast({ status: "success", message: "News created successfully" });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateNews = async (body) => {
    try {
      const response = await updateNews({ id: activeNews.id, body }).unwrap();
      // handleCloseEditor();
      setToast({ status: "success", message: "News updated successfully" });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  const handleImagePickerChange = function (e) {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      const validFormats = [
        "image/webp",
        "image/png",
        "image/jpeg",
        "image/png",
      ];

      const isValid = validFormats.includes(file.type);
      if (!isValid) {
        return alert("invalid file format");
      } // end of validation
      const data = new FormData();
      data.append("file", file);
      uploadFile(data);
    }
  };

  const uploadFile = async (data) => {
    try {
      const response = await uploadMedia(data).unwrap();
      setToast({ status: "success", message: "Media uploaded successfully" });
      setFeaturedImage(response?.media[0]?.url);
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  return (
    <Box>
      <CustomAppBar>
        <Typography variant="pageHeading">News</Typography>
        <CustomLoadingButton
          loading={isCreatingNews || isUpdatingNews}
          onClick={handleSave}
        >
          {activeNews ? "update" : "Save"}
        </CustomLoadingButton>
      </CustomAppBar>
      <Box className="page-body">
        <Box
          sx={{
            color: "grey.dark",
            backgroundColor: "grey.light",
            display: "flex",
            alignItems: "center",
            width: "max-content",
            padding: "8px 16px 8px 8px",
            borderRadius: "10px",
            marginBottom: "16px",
            cursor: "pointer",
          }}
          onClick={() =>
            setConfirmation({
              message: "you will lose any unsaved changes, continue?",
              action: handleCloseEditor,
            })
          }
        >
          <NavigateBeforeIcon />
          <Typography>Back</Typography>
        </Box>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: "grey.light",
                marginBottom: "16px",
                padding: 2,
                borderRadius: "8px",
              }}
            >
              <FormControl fullWidth>
                <Typography variant="inputLabel" sx={{ mb: 1 }}>
                  Title
                </Typography>
                <StyledTextField
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  inputProps={{maxLength:65}}

                />
              </FormControl>
            </Box>
            <CustomBlocksEditor
              blocks={blocks}
              setBlocks={setBlocks}
              deleteBlock={handleDeleteBlock}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                "& .hide": { display: "none" },
              }}
            >
              <FormControl fullWidth>
                <Typography variant="inputLabel">Categories</Typography>
                <StyledCreatableMultiSelect
                  isMulti
                  onChange={(values) => setSelectedCategories(values)}
                  value={selectedCategories}
                  options={[]}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="inputLabel">Tags</Typography>
                <StyledCreatableMultiSelect
                  isMulti
                  onChange={(values) => setSelectedTags(values)}
                  value={selectedTags}
                  options={[]}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="inputLabel">Date Published</Typography>
                <StyledTextField
                  sx={{
                    ".MuiInputBase-input": {
                      height: "40px",
                      backgroundColor: "#F1EEEE",
                    },
                  }}
                  type="date"
                  onChange={(e) => setDatePublished(e.target.value)}
                  value={datePublished}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="inputLabel">Featured Image</Typography>
                <StyledGreyButton onClick={() => filePickerRef.current.click()}>
                  Upload
                </StyledGreyButton>
                <input
                  type="file"
                  className="hide"
                  onChange={handleImagePickerChange}
                  ref={filePickerRef}
                />
                {isUploadingImage ? (
                  <p>loading...</p>
                ) : featuredImage ? (
                  <img
                    alt="featured-img"
                    src={ImageKit({
                      url: featuredImage,
                      thumbnail: true,
                    })}
                    height={70}
                    width={70}
                    style={{ marginTop: "4px" }}
                  />
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NewsCreate;
