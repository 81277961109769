import { useEffect, useRef, useState } from "react";
import CustomTextEditor from "./CustomTextEditor";
import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledGreyButton, StyledWhiteButton } from "./styled/styledButtons";
import { ControlPoint, DeleteOutline, North, South } from "@mui/icons-material";
import { StyledTextField } from "./styled/styledInputs";
import CustomImagePicker from "./CustomImagePicker";
import { useAddMediaMutation } from "../pages/media-library/mediaApiSlice";
import CustomToast from "./CustomToast";
import CustomConfirmation from "./CustomConfirmation";
import CustomLoadingCircles from "./CustomLoadingCircles";
import ImageKit from "../../helpers/ImageKit";

function CustomBlocksEditor({ blocks, setBlocks, deleteBlock }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openAddBlock = Boolean(anchorEl);
  const [dragActive, setDragActive] = useState(null);
  const [toast, setToast] = useState(null);
  const [openImagePicker, setOpenImagePicker] = useState(null);
  const [isDeletingBlock, setIsDeletingBlock] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [targetBlock, setTargetBlock] = useState(null); // to target which block to add loading to

  const [addMedia, { isLoading: uploadingMedia }] = useAddMediaMutation();

  const handleClickAddBlock = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAddBlock = () => {
    setAnchorEl(null);
  };

  const handleAddBlock = (type) => {
    if (type === "image") {
      setBlocks((prev) => [
        ...prev,
        { media: [], caption: "", openUpload: true, type: "image" },
      ]);
    } else if (type === "text") {
      setBlocks((prev) => [...prev, { content: "", type: "text" }]);
    }
    handleCloseAddBlock();
  };

  const handleMoveUp = ({ index, item }) => {
    let newBlocks = blocks;
    newBlocks.splice(index, 1);
    newBlocks.splice(index - 1, 0, item);
    setBlocks([...newBlocks]);
  };

  const handleMoveDown = ({ index, item }) => {
    let newBlocks = blocks;
    newBlocks.splice(index, 1);
    newBlocks.splice(index + 1, 0, item);
    setBlocks([...newBlocks]);
  };

  const handleDeleteBlock = async ({ index }) => {
    if (blocks[index]?.id) {
      // if it has id, it remove from endpoint
      setIsDeletingBlock(index); //start loading
      let isDeleted = await deleteBlock(blocks[index]);
      setIsDeletingBlock(null); //stop loading
      if (isDeleted) {
        // remove from array
        let newBlocks = blocks;
        newBlocks.splice(index, 1);
        setBlocks([...newBlocks]);
      }
    } else {
      // remove from array
      let newBlocks = blocks;
      newBlocks.splice(index, 1);
      setBlocks([...newBlocks]);
    }
  };

  const handleTextChange = ({ value, index }) => {
    let newBlocks = blocks;
    let contentObject = newBlocks[index]; //so we can spread the remaining values
    newBlocks[index] = { ...contentObject, content: value };
    setBlocks([...newBlocks]);
  };

  const handleCaptionChange = ({ value, index }) => {
    let newBlocks = blocks;
    const imageObject = blocks[index];
    const newImageObject = {
      ...imageObject,
      caption: value,
    };
    newBlocks[index] = newImageObject;
    setBlocks([...newBlocks]);
  };

  const handleRemoveImage = ({ index, imageIndex }) => {
    let newBlocks = blocks;
    newBlocks[index]?.media.splice(imageIndex, 1);
    setBlocks([...newBlocks]);
  };

  const handleDrag = ({ e, index }) => {
    e.preventDefault();
    if (e.type === "dragover" || e.type === "dragenter") {
      setDragActive(index);
    } else if (e.type === "dragleave") {
      setDragActive(null);
    }
  };

  const handleDragEnd = ({ e, index }) => {
    e.preventDefault();
    setDragActive(null);
    setTargetBlock(index);
    const validFormats = ["image/webp", "image/png", "image/jpeg", "image/png"];
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let file = e.dataTransfer.files[0];
      const isValid = validFormats.includes(file.type);
      if (!isValid) {
        return alert("invalid file format");
      } // end of validation

      const data = new FormData();
      data.append("file", file);
      uploadImage(data, index);
    }
  };

  const toggleAddImage = ({ index }) => {
    const newBlocks = blocks;
    const imageObject = blocks[index];
    if (imageObject?.media?.length === 3) {
      // validate the number of images
      // return alert("maximum images selected");
    }
    const newImageObject = {
      ...imageObject,
      openUpload: !imageObject.openUpload,
    };
    newBlocks[index] = newImageObject;
    setBlocks([...newBlocks]);
  };

  const uploadImage = async (file, index) => {
    try {
      const response = await addMedia(file).unwrap();
      console.log(response);
      if (response.media) {
        setToast({ status: "success", message: "Media uploaded successfully" });
        let image = { url: response.media[0]?.url, id: response.media[0]?.id };
        addOrDropImage(image, index);
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to upload media" });
      console.log(error);
    }
  };

  const addOrDropImage = (image, index) => {
    const imageExists = blocks[index]?.media?.find(
      (item) => item.id === image.id
    );
    if (!!imageExists) {
      // remove the image
      const newBlocks = blocks;
      const newMedia = blocks[index]?.media?.filter(
        (item) => item.id !== image.id
      ); //returns array without the selected image
      const imageObject = blocks[index];

      const newImageObject = {
        ...imageObject,
        media: newMedia,
      };
      newBlocks[index] = newImageObject; //mutate the image object in out new array
      setBlocks([...newBlocks]);
    } else {
      if (blocks[index]?.media?.length === 3) {
        //image number validation
        return alert("max images");
      }
      const newBlocks = blocks;
      newBlocks[index]?.media.push({
        url: image.url, //the thumbnail
        id: image.id,
      }); //mutaute new array
      setBlocks([...newBlocks]);
    }
  };

  return (
    <Box
      className="container"
      sx={{
        color: "grey.dark",
        "& .card": {
          backgroundColor: "grey.light",
          width: "100%",
          mb: 2,
          borderRadius: "4px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        },
        "& .card-heading": {
          display: "flex",
          justifyContent: "space-between",
          "& .title": {
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "36px",
          },
        },

        "& .card-body": {
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        },
        "& .input-group": {
          display: "grid",
          gridTemplateColumns: "1fr 4fr",
          alignItems: "center",
        },

        "& .label ": {
          fontSize: "16px",
          fontWeight: 600,
        },

        "& .image-picker ": {
          display: "flex",
          flexDirection: "column",
          padding: "8px 16px",
          border: "2px dashed",
          borderColor: "primary.main",
          backgroundColor: "white",
          "& .image-count ": {
            fontSize: "10px",
            fontWeight: 300,
            alignSelf: "flex-end",
            cursor: "pointer",
          },
          "& .upload-box ": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            pb: 1,
          },
        },

        "& .image-view-list ": {
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          gap: "20px",
          paddingBottom: "8px",
        },

        "& .image ": {
          display: "flex",
          alignItems: "flex-start",
        },

        "& .hide": {
          display: "none",
        },
        "& .image > .close ": {
          padding: " 0px 4px",
          backgroundColor: "grey.light",
          color: "grey.dark",
          fontWeight: 600,
          cursor: "pointer",
        },

        "& .btn ": {
          padding: "10px",
          display: "flex",
          gap: "6px",
          alignItems: "center",
          cursor: "pointer",
        },

        "& .btn-icon ": {
          border: " 1px solid grey.dark",
          borderRadius: "100%",
          width: "16px",
          display: "inline",
        },
      }}
    >
      {/* image picker modal */}

      <CustomImagePicker
        open={openImagePicker}
        handleClose={() => setOpenImagePicker(null)}
        blocks={blocks}
        addOrDropImage={addOrDropImage}
        setToast={setToast}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <Box className="main-content">
        {blocks?.map((item, index) => (
          <React.Fragment key={index}>
            {item.type === "image" ? (
              <Box className="card">
                <Box className="card-heading">
                  <Typography className="title">Image</Typography>
                  <ButtonGroup
                    sx={{
                      backgroundColor: "white",
                      color: "grey.dark",
                      borderColor: "grey.dark",
                    }}
                    size="small"
                    aria-label="small button group"
                  >
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="move up"
                      disabled={index === 0}
                      onClick={() => handleMoveUp({ index, item })}
                    >
                      <North />
                    </Button>
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="move down"
                      disabled={index === blocks.length - 1}
                      onClick={() => handleMoveDown({ index, item })}
                    >
                      <South />
                    </Button>
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="delete"
                      onClick={() =>
                        setConfirmation({
                          message:
                            "Are you sure you want to delete this block and its content?",
                          action: () => handleDeleteBlock({ index }),
                        })
                      }
                      disabled={isDeletingBlock === index}
                    >
                      {isDeletingBlock === index ? (
                        <CustomLoadingCircles height="14" width="14" />
                      ) : (
                        <DeleteOutline />
                      )}
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box className="card-body">
                  <Box className="input-group">
                    <span className="label">upload image*</span>
                    <Box
                      className="image-picker"
                      sx={{
                        borderColor:
                          dragActive === index ? "green" : "primary.main",
                      }}
                    >
                      <span
                        className="image-count"
                        onClick={() => toggleAddImage({ index })}
                      >
                        {item.media?.length}/3
                      </span>
                      {item.openUpload ? (
                        <Box
                          className="upload-box"
                          id="drop_zone"
                          onDrop={(e) => handleDragEnd({ e, index })}
                          onDragOver={(e) => handleDrag({ e, index })}
                          onDragEnter={(e) => handleDrag({ e, index })}
                          onDragLeave={(e) => handleDrag({ e, index })}
                        >
                          {dragActive === index ? (
                            <h5>Drop</h5>
                          ) : (
                            <>
                              <span
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                Drop file here
                              </span>
                              <span
                                style={{
                                  fontWeight: 500,
                                }}
                              >
                                or
                              </span>
                              <StyledGreyButton
                                onClick={() => {
                                  setOpenImagePicker({ index });
                                  setTargetBlock(index);
                                }}
                                disabled={
                                  targetBlock === index && uploadingMedia
                                }
                              >
                                {targetBlock === index && uploadingMedia ? (
                                  <>
                                    Uploading
                                    <CustomLoadingCircles
                                      height="20px"
                                      width="20px"
                                      color="rgba(0, 0, 0, 0.32)"
                                    />
                                  </>
                                ) : (
                                  "Pick from the library"
                                )}
                              </StyledGreyButton>
                            </>
                          )}
                        </Box>
                      ) : (
                        <Box className="image-view-list">
                          {item.media?.map((image, i) => (
                            <Box className="image" key={i}>
                              <img
                                src={ImageKit({
                                  url: image?.url,
                                  thumbnail: true,
                                })}
                                alt=""
                                height={50}
                                width={50}
                                style={{ borderRadius: "8px" }}
                              />
                              <span
                                className="close"
                                onClick={() =>
                                  handleRemoveImage({ index, imageIndex: i })
                                }
                              >
                                x
                              </span>
                            </Box>
                          ))}
                          <ControlPoint
                            onClick={() => toggleAddImage({ index })}
                            sx={{
                              height: "25px",
                              width: "25px",
                              cursor: "pointer",
                              color: "primary.main",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box className="input-group">
                    <span className="label">caption</span>
                    <StyledTextField
                      name="caption"
                      id="caption"
                      value={item.caption}
                      onChange={(e) => {
                        handleCaptionChange({ value: e.target.value, index });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="card">
                <Box className="card-heading">
                  <Typography className="title">Text</Typography>
                  <ButtonGroup
                    sx={{
                      backgroundColor: "white",
                      color: "grey.dark",
                      borderColor: "grey.dark",
                    }}
                    size="small"
                    aria-label="small button group"
                  >
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="move up"
                      disabled={index === 0}
                      onClick={() => handleMoveUp({ index, item })}
                    >
                      <North />
                    </Button>
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="move down"
                      disabled={index === blocks.length - 1}
                      onClick={() => handleMoveDown({ index, item })}
                    >
                      <South />
                    </Button>
                    <Button
                      sx={{
                        borderColor: "grey.dark",
                        color: "grey.dark",
                        p: 0,
                      }}
                      title="delete"
                      onClick={() =>
                        setConfirmation({
                          message:
                            "Are you sure you want to delete this block and its content?",
                          action: () => handleDeleteBlock({ index }),
                        })
                      }
                      disabled={isDeletingBlock === index}
                    >
                      {isDeletingBlock === index ? (
                        <CustomLoadingCircles height="14" width="14" />
                      ) : (
                        <DeleteOutline />
                      )}
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box className="card-body">
                  <CustomTextEditor
                    value={item.content}
                    onChange={(content) => {
                      handleTextChange({ value: content, index });
                    }}
                  />
                </Box>
              </Box>
            )}
          </React.Fragment>
        ))}
        <StyledWhiteButton
          startIcon={<ControlPoint />}
          onClick={handleClickAddBlock}
        >
          Add block
        </StyledWhiteButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAddBlock}
          onClose={handleCloseAddBlock}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleAddBlock("text")}>Text</MenuItem>
          <MenuItem onClick={() => handleAddBlock("image")}>Image</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default CustomBlocksEditor;
