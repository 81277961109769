// create and export a function that will take a file url and return an imagekit version
const ImageKit = ({ url, thumbnail, grayscale, blur, smartCrop, videoThumbnail }) => {
  const ID = process.env.REACT_APP_IMAGE_KIT_ID;
  const FOLDER = process.env.REACT_APP_SPACE_FOLDER;
  const ID_WITH_FOLDER = `${ID}/${FOLDER ? `${FOLDER}/` : ""}`;
  const imageKitUrl = `https://ik.imagekit.io/${ID_WITH_FOLDER}`;
  const fileName = url?.split("/")?.pop();
  if (videoThumbnail){
    const videoOption = '/ik-thumbnail.jpg';
    return `${imageKitUrl}${fileName}${videoOption}`;
  }else{
    const imageKitOptions = `?tr=${thumbnail ? "w-300,h-300," : ""}${
      grayscale ? "e-grayscale," : ""
    }${blur ? "blur-10," : ""}${smartCrop ? "fo-auto" : ""}`;
    return `${imageKitUrl}${fileName}${imageKitOptions}`;
  }
};

export default ImageKit;
