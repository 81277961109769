import { Box, Modal, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

function CustomDatePicker({ selectedDate, setSelectedDate, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Modal contains the calender */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
              handleClose();
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <p
            style={{
              textAlign: "end",
              marginInline: "30px",
              cursor: "pointer",
              color: "#1976d2",
              "&:hover": {
                color: "red",
              },
            }}
            onClick={() => {
              setSelectedDate(new Date());
            }}
          >
            Today
          </p>
        </LocalizationProvider>
      </Box>
    </Modal>
  );
}

export default CustomDatePicker;
