import CustomModal from "./CustomModal";
function CustomVideoPlayer({ currentVideo, setCurrentVideo }) {
  console.log("playing", currentVideo);
  return (
    <CustomModal
      open={!!currentVideo}
      handleClose={() => setCurrentVideo(null)}
    >
      <h6>{currentVideo?.title}</h6>

      <video controls id="video1" width="100%">
        <source src={currentVideo?.url} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
    </CustomModal>
  );
}

export default CustomVideoPlayer;
