import { apiSlice } from "../../../app/apiSlice";

const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDataTrends: builder.query({
      query: ({ date }) => `/dashboard?date=${date}`,
    }),
  }),
});

export const { useGetDataTrendsQuery } = dashboardApiSlice;
