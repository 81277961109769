import React, { useEffect, useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import { StyledTextField } from "../../custom/styled/styledInputs";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import {
  useCreatePageMutation,
  useDeletePageBlockMutation,
  useUpdatePageMutation,
} from "./pagesApiSlice";

function PagesCreate({
  handleCloseEditor,
  setToast,
  activePage,
  setConfirmation,
}) {
  const [blocks, setBlocks] = useState([]);
  const [title, setTitle] = useState("");

  const [createPage, { isLoading: isCreatingPage }] = useCreatePageMutation();
  const [updatePage, { isLoading: isUpdatingPage }] = useUpdatePageMutation();
  const [deleteBlock] = useDeletePageBlockMutation();

  useEffect(() => {
    //fill up initial state for update Page
    if (activePage) {
      setTitle(activePage.title || "");
      setBlocks(
        // make the blocks array editable
        activePage?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
    }
  }, [activePage]);

  const handleSave = () => {
    const body = {
      title: title,
      blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };

    console.log("body", body);
    if (!title) {
      return setToast({ status: "error", message: "Page title is required" });
    }

    if (activePage) {
      handleUpdatePage(body);
    } else {
      handleCreatePage(body);
    }
  };

  const handleCreatePage = async (body) => {
    try {
      await createPage(body).unwrap();
      handleCloseEditor();
      setToast({ status: "success", message: "Page created successfully" });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePage = async (body) => {
    try {
      const response = await updatePage({ id: activePage.id, body }).unwrap();
      handleCloseEditor();
      setToast({ status: "success", message: "Page updated successfully" });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  return (
    <Box>
      <CustomAppBar>
        <Typography variant="pageHeading">Pages</Typography>
        <CustomLoadingButton
          loading={isCreatingPage || isUpdatingPage}
          onClick={handleSave}
        >
          {activePage ? "update" : "Save"}
        </CustomLoadingButton>
      </CustomAppBar>
      <Box className="page-body">
        <Box
          sx={{
            color: "grey.dark",
            backgroundColor: "grey.light",
            display: "flex",
            alignItems: "center",
            width: "max-content",
            padding: "8px 16px 8px 8px",
            borderRadius: "10px",
            marginBottom: "16px",
            cursor: "pointer",
          }}
          onClick={() =>
            setConfirmation({
              message: "you will lose any unsaved changes, continue?",
              action: handleCloseEditor,
            })
          }
        >
          <NavigateBeforeIcon />
          <Typography>Back</Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "grey.light",
            marginBottom: "16px",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <FormControl fullWidth>
            <Typography variant="inputLabel" sx={{ mb: 1 }}>
              Title
            </Typography>
            <StyledTextField
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
        </Box>
        <CustomBlocksEditor
          blocks={blocks}
          setBlocks={setBlocks}
          deleteBlock={handleDeleteBlock}
        />
      </Box>
    </Box>
  );
}

export default PagesCreate;
