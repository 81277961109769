import React, { useState } from "react";
import { Box } from "@mui/material";
import ReferencesList from "./ReferencesList";
import CreateReference from "./CreateReference";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";

function References() {
  const [openEditor, setOpenEditor] = useState(false);
  const [toast, setToast] = useState(null);
  const [activeReference, setActiveReference] = useState(null);
  const [confirmation, setConfirmation] = useState(null);

  const handleCloseEditor = () => {
    setOpenEditor(false);
    setActiveReference(null);
  };

  return (
    <Box>
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {openEditor ? (
        <CreateReference
          handleCloseEditor={handleCloseEditor}
          setConfirmation={setConfirmation}
          activeReference={activeReference}
          setToast={setToast}
        />
      ) : (
        <ReferencesList
          setOpenEditor={setOpenEditor}
          setActiveReference={setActiveReference}
          setToast={setToast}
          setConfirmation={setConfirmation}
        />
      )}
    </Box>
  );
}

export default References;
