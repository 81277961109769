import React from "react";
import { StyledFilledButton } from "./styled/styledButtons";
import { Circles } from "react-loader-spinner";

function CustomLoadingButton({ children, loading, type, onClick }) {
  return (
    <StyledFilledButton
      disabled={loading}
      type={type}
      onClick={onClick}
      sx={{
        backgroundColor: loading ? "grey.light" : "primary.main",
        color: loading ? "primary.main" : "#FFF",
        border: loading ? "1px solid #603229" : "",
        minWidth: "150px",
      }}
    >
      {loading ? (
        <Circles
          height="30"
          width="30"
          color="#603E38"
          ariaLabel="circles-loading"
          wrapperStyle={{ padding: "4px" }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        children
      )}
    </StyledFilledButton>
  );
}

export default CustomLoadingButton;
