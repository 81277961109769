import { Box } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";

function CustomSearch(props) {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "primary.main",
        display: "flex",
        borderRadius: "5px",
        backgroundColor: "grey.light",
      }}
    >
      <Box
        {...props}
        component="input"
        sx={{
          outline: "none",
          border: "none",
          height: "40px",
          width: "341",
          margin: "1px",
          backgroundColor: "grey.light",
          px: 1,
        }}
      />
      <Box
        sx={{
          backgroundColor: "primary.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "52px",
        }}
      >
        <SearchIcon />
      </Box>
    </Box>
  );
}

export default CustomSearch;
