import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomModal from "../../custom/CustomModal";
import { StyledTextField } from "../../custom/styled/styledInputs";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import {
  useCreateContactMutation,
  useUpdateContactMutation,
} from "./companyApiSlice";

function AddContactModal({
  openAddContact,
  handleClose,
  setToast,
  targetContact,
  selectedType,
}) {
  const [createContact, { isLoading: isAddingContact }] =
    useCreateContactMutation();
  const [updateContact, { isLoading: isUpdatingContact }] =
    useUpdateContactMutation();

  const formik = useFormik({
    initialValues: {
      type: targetContact?.type || selectedType || "",
      value: targetContact?.value || "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      type: yup.string().required("required"),
      value: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const data = {
        type: values.type,
        value: values.value,
      };

      if (targetContact) {
        handleUpdateContact(data);
      } else {
        handleCreateContact(data);
      }
    },
  });

  const handleCreateContact = async (data) => {
    try {
      const response = await createContact(data);
      setToast({
        status: "success",
        message: response.message || "Contact created successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateContact = async (data) => {
    // implement update here
    const values = { body: data, id: targetContact?.id };
    try {
      const response = await updateContact(values);
      setToast({
        status: "success",
        message: response.message || "contact updated successfully",
      });
      handleClose();
    } catch (error) {}
  };

  return (
    <CustomModal open={openAddContact} handleClose={handleClose}>
      <Box
        sx={{
          m: 5,
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="inputLabel">{formik.values.type}</Typography>
              <StyledTextField
                id="value"
                name="value"
                value={formik.values.value}
                onChange={formik.handleChange}
              />
              <FormHelperText variant="error">
                {formik.touched.value && formik.errors.value}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CustomLoadingButton
              sx={{ mt: 3 }}
              type="submit"
              loading={isAddingContact || isUpdatingContact}
            >
              {targetContact ? "Update" : "Add"}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
}

export default AddContactModal;
