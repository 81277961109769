import { Box } from "@mui/material";
import React from "react";

function CustomAppBar({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: "grey.light",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 3,
        flexWrap: "wrap",
      }}
    >
      {children}
    </Box>
  );
}

export default CustomAppBar;
