import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Reference"],
});

const referenceApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getReference: builder.query({
      query: () => `/reference`,
      providesTags: ["Reference"],
    }),
    createReference: builder.mutation({
      query: (values) => ({
        url: "/reference",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Reference"],
    }),
    updateReference: builder.mutation({
      query: (values) => ({
        url: `/reference/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["Reference"],
    }),
    deleteReference: builder.mutation({
      query: (value) => ({
        url: `/reference/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reference"],
    }),
    deleteReferenceBlock: builder.mutation({
      query: (value) => ({
        url: `/reference/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reference"],
    }),
  }),
});

export const {
  useGetReferenceQuery,
  useCreateReferenceMutation,
  useUpdateReferenceMutation,
  useDeleteReferenceMutation,
  useDeleteReferenceBlockMutation,
} = referenceApiSlice;
