import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Stack, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import { StyledTextField } from "../../custom/styled/styledInputs";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import {
  useCreateMetaMutation,
  useDeleteMetaBlockMutation,
  useUpdateMetaMutation,
} from "./metaApiSlice";

function MetaCreate({
  handleCloseEditor,
  setToast,
  activeMeta,
  selectedType,
  setConfirmation,
}) {
  const [blocks, setBlocks] = useState([]);
  const [title, setTitle] = useState("");
  const [metaType, setMetaType] = useState("");

  const [createMeta, { isLoading: isCreatingMeta }] = useCreateMetaMutation();
  const [updateMeta, { isLoading: isUpdatingNews }] = useUpdateMetaMutation();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeleteMetaBlockMutation();

  useEffect(() => {
    //fill up initial state for update meta
    if (activeMeta) {
      setTitle(activeMeta.title || "");
      setMetaType(activeMeta.type || "");
      setBlocks(
        // make the blocks array editable
        activeMeta?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
    } else {
      setMetaType(selectedType || "");
    }
  }, [activeMeta, selectedType]);

  const handleSave = () => {
    const body = {
      type: metaType,
      title: title,
      blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };

    console.log("body", body);
    if (!title) {
      return setToast({ status: "error", message: "Meta title is required" });
    }

    if (activeMeta) {
      handleUpdateMeta(body);
    } else {
      handleCreateMeta(body);
    }
  };

  const handleCreateMeta = async (body) => {
    try {
      const response = await createMeta(body).unwrap();
      handleCloseEditor();
      setToast({ status: "success", message: "meta created successfully" });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateMeta = async (body) => {
    try {
      const response = await updateMeta({ id: activeMeta.id, body }).unwrap();
      // handleCloseEditor();
      setToast({ status: "success", message: "Meta updated successfully" });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  return (
    <Box>
      <CustomAppBar>
        <Typography variant="pageHeading">Meta</Typography>
        <CustomLoadingButton
          loading={isCreatingMeta || isUpdatingNews}
          onClick={handleSave}
        >
          {activeMeta ? "update" : "Save"}
        </CustomLoadingButton>
      </CustomAppBar>
      <Box className="page-body">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              color: "grey.dark",
              backgroundColor: "grey.light",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              padding: "8px 16px 8px 8px",
              borderRadius: "10px",
              marginBottom: "16px",
              cursor: "pointer",
            }}
            onClick={() =>
              setConfirmation({
                message: "you will lose any unsaved changes, continue?",
                action: handleCloseEditor,
              })
            }
          >
            <NavigateBeforeIcon />
            <Typography>Back</Typography>
          </Box>
          <Typography>
            Type: <b>{metaType}</b>
          </Typography>
        </Stack>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "grey.light",
                marginBottom: "16px",
                padding: 2,
                borderRadius: "8px",
              }}
            >
              <FormControl fullWidth>
                <Typography variant="inputLabel" sx={{ mb: 1 }}>
                  Title
                </Typography>
                <StyledTextField
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>
            </Box>
            <CustomBlocksEditor
              blocks={blocks}
              setBlocks={setBlocks}
              deleteBlock={handleDeleteBlock}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MetaCreate;
