import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Main from "./Main";
import SideNav from "./SideNav";

const drawerWidth = 352;

function Layout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "grey.light" }}>
      <CssBaseline />
      <SideNav
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Main drawerWidth={drawerWidth} />
    </Box>
  );
}

export default Layout;
