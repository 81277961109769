import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "../components/pages/auth/authSlice.js";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,

  prepareHeaders: (headers, { getState }) => {
    const credentials = JSON.parse(localStorage.getItem("credentials"));
    if (credentials?.token) {
      headers.set("authorization", `Bearer ${credentials.token}`);
    }
    return headers;
  },
});

const baseQuerywithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // unauthorized
    api.dispatch(logout()); // logout user
    api.dispatch(apiSlice.util.resetApiState());
  }

  return result; //if everything is ok, return the result
};

export const apiSlice = createApi({
  baseQuery: baseQuerywithAuth,
  endpoints: (builder) => ({}),
});
