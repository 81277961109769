import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Meta"],
});

const metaApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getMeta: builder.query({
      query: () => `/metas`,
      providesTags: ["Meta"],
    }),
    createMeta: builder.mutation({
      query: (values) => ({
        url: "/meta",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Meta"],
    }),
    updateMeta: builder.mutation({
      query: (values) => ({
        url: `/meta/${values.id}`,
        method: "PATCH",
        body: values.body,
      }),
      invalidatesTags: ["Meta"],
    }),
    deleteMeta: builder.mutation({
      query: (value) => ({
        url: `/meta/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Meta"],
    }),
    deleteMetaBlock: builder.mutation({
      query: (value) => ({
        url: `/meta/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Meta"],
    }),
  }),
});

export const {
  useGetMetaQuery,
  useCreateMetaMutation,
  useUpdateMetaMutation,
  useDeleteMetaBlockMutation,
  useDeleteMetaMutation,
} = metaApiSlice;
