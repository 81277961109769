import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomInnerSideNav from "../../custom/CustomInnerSideNav";
import CustomToast from "../../custom/CustomToast";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import About from "./About";
import {
  useCreateAboutMutation,
  useCreateCultureMutation,
} from "./companyApiSlice";
import Contact from "./Contact";
import Culture from "./Culture";
import Documents from "./Documents";

function CompanyProfile() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeAboutTab, setActiveAboutTab] = useState(0);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [cultureBlocks, setCultureBlocks] = useState([]);
  const [aboutBlocks, setAboutBlocks] = useState([]);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);

  const tabs = ["Company culture", "About", "Contact", "Documents"];
  const aboutTabs = ["Company", "Team"];

  const [updateCulture, { isLoading: isUpdatingCulture }] =
    useCreateCultureMutation();
  const [updateAbout, { isLoading: isUpdatingAbout }] =
    useCreateAboutMutation();

  const handleSaveCultureBlocks = async () => {
    const body = {
      blocks: cultureBlocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };
    try {
      const response = await updateCulture(body).unwrap();
      console.log(response);
      setToast({
        status: "success",
        message: "culture updated successfully",
      });
    } catch (error) {
      setToast({
        status: "error",
        message: error.message || "error updating culture",
      });
      console.log("error");
    }
  };

  const handleSaveAboutBlocks = async () => {
    const body = {
      blocks: aboutBlocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };
    console.log("about", body);
    try {
      const response = await updateAbout(body).unwrap();
      console.log(response);
      setToast({
        status: "success",
        message: "about us updated successfully",
      });
    } catch (error) {
      setToast({
        status: "error",
        message: error.message || "error updating about",
      });
      console.log("error");
    }
  };

  return (
    <Box>
      {/* modals */}
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomAppBar>
        <Typography variant="pageHeading">Company Profile</Typography>
        {activeTab === 0 && (
          <StyledFilledButton onClick={handleSaveCultureBlocks}>
            Save
          </StyledFilledButton>
        )}
        {activeTab === 1 && activeAboutTab === 0 && (
          <StyledFilledButton onClick={handleSaveAboutBlocks}>
            Save
          </StyledFilledButton>
        )}
        {activeTab === 1 && activeAboutTab === 1 && (
          <StyledFilledButton onClick={() => setOpenAddMember(true)}>
            Add
          </StyledFilledButton>
        )}
      </CustomAppBar>
      {/* end of top appbar */}
      <Box className="page-body">
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={3}>
            {/* profile menu */}
            <CustomInnerSideNav
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Grid>
          <Grid item xs={9}>
            {/* display main content here */}
            {activeTab === 0 && (
              <Culture
                blocks={cultureBlocks}
                setBlocks={setCultureBlocks}
                setToast={setToast}
              />
            )}
            {activeTab === 1 && (
              <About
                blocks={aboutBlocks}
                setBlocks={setAboutBlocks}
                tabs={aboutTabs}
                activeTab={activeAboutTab}
                setActiveTab={setActiveAboutTab}
                openAddMember={openAddMember}
                setOpenAddMember={setOpenAddMember}
                setToast={setToast}
                setConfirmation={setConfirmation}
              />
            )}
            {activeTab === 2 && <Contact setToast={setToast} />}
            {activeTab === 3 && <Documents setToast={setToast} />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CompanyProfile;
