import { Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

function Main({ drawerWidth }) {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        minHeight: "100vh",
        "& .page-body": {
          backgroundColor: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          minHeight: "80vh",
        },
      }}
    >
      {/* <Header drawerWidth={drawerWidth} /> */}
      {/* <Toolbar /> */}
      <Outlet />
    </Box>
  );
}

export default Main;
