import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomSearch from "../../custom/CustomSearch";
import CustomToast from "../../custom/CustomToast";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import { useDeleteMediaMutation, useGetMediaQuery } from "./mediaApiSlice";
import UploadDialog from "./UploadDialog";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import { DeleteForever, PlayCircleFilled, SaveAlt } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import ImageKit from "../../../helpers/ImageKit";
import CustomVideoPlayer from "../../custom/CustomVideoPlayer";

function MediaLibrary() {
  const [activeTab, setActiveTab] = useState(0);
  const [openUpload, setOpenUpload] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [displayDelete, setDisplayDelete] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [mediaType, setMediaType] = useState("all");
  const [searchParam, setSearchParam] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const { data: media, isFetching: isFetchingMedia } = useGetMediaQuery({
    type: mediaType,
    page,
  });

  const [deleteMedia, { isDeletingMedia }] = useDeleteMediaMutation();

  const toggleMedia = (tab) => {
    if (tab === 0) {
      setMediaType("all");
    }
    if (tab === 1) {
      setMediaType("type/image");
    }
    if (tab === 2) {
      setMediaType("type/video");
    }
    if (tab === 3) {
      setMediaType("type/pdf");
    }
  };

  const handleDeleteMedia = async (item) => {
    try {
      const response = await deleteMedia(item).unwrap();
      console.log(response);
      setToast({ status: "success", message: "Media deleted successfully" });
    } catch (error) {
      setToast({
        status: "error",
        message: error.message || "Media deleted successfully",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchParam) {
      setSearchResult([
        ...media?.media?.filter((item) => item.id === parseInt(searchParam)),
      ]);
    }
  }, [searchParam]);

  useEffect(() => {
    // reset page number when active tab changes
    setPage(1);
  }, [mediaType]);

  const mediaToDisplay = searchParam ? searchResult : media?.media;

  return (
    <Box>
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomVideoPlayer
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
      />
      <UploadDialog
        open={openUpload}
        setOpen={setOpenUpload}
        setToast={setToast}
      />
      <CustomAppBar>
        <Typography variant="pageHeading">Media Library</Typography>
        <StyledFilledButton onClick={() => setOpenUpload(true)}>
          Upload
        </StyledFilledButton>
      </CustomAppBar>
      <Box
        className="page-body"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* header tabs */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              "& p": {
                color: "grey.light",
                padding: "4px 20px",
                cursor: "pointer",
                fontWeight: 600,
                fontSize: "28px",
                lineHeight: "42px",
                borderRadius: "8px",
              },
              "& .active": {
                backgroundColor: "grey.light",
                color: "grey.dark",
              },
            }}
          >
            <Typography
              className={activeTab === 0 ? "active" : ""}
              onClick={() => {
                setActiveTab(0);
                toggleMedia(0);
              }}
            >
              All files
            </Typography>
            <Typography
              className={activeTab === 1 ? "active" : ""}
              onClick={() => {
                setActiveTab(1);
                toggleMedia(1);
              }}
            >
              Images
            </Typography>
            <Typography
              className={activeTab === 2 ? "active" : ""}
              onClick={() => {
                setActiveTab(2);
                toggleMedia(2);
              }}
            >
              Videos
            </Typography>
            <Typography
              className={activeTab === 3 ? "active" : ""}
              onClick={() => {
                setActiveTab(3);
                toggleMedia(3);
              }}
            >
              PDF
            </Typography>
          </Box>
          <Box>
            <CustomSearch
              placeholder="Media ID"
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </Box>
        </Box>
        {/* end of header tabs */}
        {isFetchingMedia ? (
          <CustomLoadingCircles />
        ) : (
          <>
            <Grid container rowSpacing={3} columnSpacing={2} sx={{ flex: 1 }}>
              {mediaToDisplay.length ? (
                mediaToDisplay.map((item, i) => (
                  <Grid item key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Box
                      onMouseOver={() => setDisplayDelete(item.id)}
                      onMouseLeave={() => setDisplayDelete(null)}
                      sx={{
                        "& img": {
                          height: "181px",
                          width: "100%",
                        },
                        "& .media-card": {
                          height: "181px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "black",
                          "& .icon": {
                            color: "white",
                            height: "50px",
                            width: "50px",
                            cursor: "pointer",
                          },
                        },
                      }}
                    >
                      {item.type === "image" && (
                        <img
                          src={ImageKit({ url: item.url, thumbnail: true })}
                          alt=""
                          height={181}
                          width={"100%"}
                        />
                      )}
                      {item.type === "video" && (
                        <Box className="media-card">
                          <PlayCircleFilled
                            onClick={() => setCurrentVideo(item)}
                            className="icon"
                          />
                        </Box>
                      )}
                      {item.type === "pdf" && (
                        <Box
                          className="media-card"
                          component="a"
                          href={item.url}
                          download
                        >
                          <SaveAlt className="icon" />
                        </Box>
                      )}

                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "30px",
                          color: "grey.dark",
                        }}
                      >
                        {item.id} -{" "}
                        {format(parseISO(item.updated_at), "yy/MM/dd")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "grey.dark",
                          }}
                        >
                          {item.resolution} {item.size} {item.type}
                        </Typography>
                        {displayDelete === item.id && (
                          <DeleteForever
                            sx={{
                              color: "red",
                              height: "18px",
                              width: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setConfirmation({
                                message:
                                  "Are you sure you want to delete this media?",
                                action: () => handleDeleteMedia(item),
                              })
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Box textAlign="center" width="100%">
                  <h3>No results found</h3>
                </Box>
              )}
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Pagination
                count={media?.pagination?.pages}
                page={page}
                onChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default MediaLibrary;
