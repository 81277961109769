import { DeleteForever } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

function CustomInnerSideNav({
  children,
  tabs,
  activeTab,
  setActiveTab,
  handleDelete,
}) {
  const [displayDelete, setDisplayDelete] = useState(null);

  return (
    <Box
      sx={{
        backgroundColor: "grey.light",
        height: "100vh",
        borderRadius: "10px",
        textAlign: "center",
        "& .list-container": {
          textAlign: "left",
          pt: "20px",
          pb: "40px",
          "& .list-item": {
            p: "8px 16px",
            fontWeight: 500,
            fontSize: "26px",
            lineHeight: "39px",
            color: "grey.lightText",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          },
          "& .active": {
            backgroundColor: "primary.main",
            color: "white",
          },
        },
      }}
    >
      <Box className="list-container">
        {tabs?.map((item, i) => (
          <Box
            key={i}
            className={`list-item ${activeTab === i && "active"}`}
            onMouseOver={() => setDisplayDelete(i)}
            onMouseLeave={() => setDisplayDelete(null)}
          >
            <Typography sx={{ flex: 1 }} onClick={() => setActiveTab(i)}>
              {item}
            </Typography>

            {handleDelete && displayDelete === i && (
              <DeleteForever
                onClick={() => handleDelete(i)}
                title="delete segment"
              />
            )}
          </Box>
        ))}
      </Box>
      {children}
    </Box>
  );
}

export default CustomInnerSideNav;
