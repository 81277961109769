import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { MoreHoriz, MoreVert } from "@mui/icons-material";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import CustomToast from "../../custom/CustomToast";

import { useDeletePageMutation, useGetPagesQuery } from "./pagesApiSlice";

function PagesList({ setOpenEditor, setActivePage }) {
  const { data: pagesList, isLoading: isLoadingPages } = useGetPagesQuery();
  const [deletePage] = useDeletePageMutation();

  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetPage, setTargetPage] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, page) => {
    setAnchorEl(event.currentTarget);
    setTargetPage(page);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTargetPage(null);
  };

  const handleDeletePage = async (page) => {
    try {
      await deletePage(page).unwrap();
      setToast({ status: "success", message: "page deleted successfully" });
    } catch (error) {
      setToast({
        status: "error",
        message: error?.data?.message || "error deleting page",
      });
      console.log(error);
    }
  };

  return (
    <Box>
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomAppBar>
        <Typography variant="pageHeading">Pages</Typography>
        <StyledFilledButton onClick={() => setOpenEditor(true)}>
          Create
        </StyledFilledButton>
      </CustomAppBar>
      {isLoadingPages ? (
        <Box className="page-body">
          <CustomLoadingCircles />
        </Box>
      ) : (
        <Box className="page-body">
          {pagesList?.pages?.map((page) => (
            <Box
              key={page.id}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "grey.light",
                flexWrap: "wrap",
                borderRadius: "10px",
                padding: "8px 16px",
                mb: 2,
                "& .title": {
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "45px",
                },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography className="title">{page.title}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <MoreVert
                  sx={{
                    display: { xs: "none", md: "block" },
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClick(e, page)}
                />
                <MoreHoriz
                  sx={{
                    display: { xs: "block", md: "none" },
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClick(e, page)}
                />
              </Box>
            </Box>
          ))}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setActivePage(targetPage);
                setOpenEditor(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                setConfirmation({
                  message: "Are you sure you want to delete this item?",
                  action: () => {
                    handleDeletePage(targetPage);
                    handleClose();
                  },
                })
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}

export default PagesList;
