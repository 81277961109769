import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#603229",
    },
    grey: {
      dark: "rgba(0, 0, 0, 0.7)",
      light: "#F1EEEE",
      lightText: "rgba(0, 0, 0, 0.32);",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Roboto", "Arial"],
    pageHeading: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "34px",
      lineHeight: "51px",
      textTransform: "capitalize",
      display: "block",
    },
    inputLabel: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "capitalize",
      display: "block",
      mb: 1,
      color: "rgba(0, 0, 0, 0.7)",
    },
  },
});
