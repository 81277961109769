import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import { StyledWhiteButton } from "../../custom/styled/styledButtons";
import CustomInnerSideNav from "../../custom/CustomInnerSideNav";
import { AddCircle } from "@mui/icons-material";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import AddPortfolioModal from "./AddPortfolioModal";
import {
  useDeletePortfolioBlockMutation,
  useDeletePortfolioMutation,
  useGetAllPortfolioQuery,
  useUpdatePortfolioMutation,
} from "./portfolioApiSlice";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import CustomToast from "../../custom/CustomToast";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";
import { StyledTextField } from "../../custom/styled/styledInputs";

function Portfolio() {
  const [title, setTitle] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [toast, setToast] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [openAddSector, setOpenAddSector] = useState(false);
  const [confirmation, setConfirmation] = useState(null);

  const { data: portfolios, isLoading: isLoadingSectors } =
    useGetAllPortfolioQuery();
  const [updatePortfolio, { isLoading: isUpdatingPortfolio }] =
    useUpdatePortfolioMutation();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeletePortfolioBlockMutation();
  const [deletePortfolio] = useDeletePortfolioMutation();

  const tabs = portfolios?.portfolios?.map((sector) => sector.name);
  const activePortfolio = portfolios?.portfolios?.[activeTab];

  useEffect(() => {
    setTitle(activePortfolio?.name || "");
    setBlocks(
      activePortfolio?.blocks?.map((block) =>
        block.type === "image"
          ? // make the media  array editable
            { ...block, media: block.media?.map((media) => media) }
          : block
      ) || []
    );
  }, [activeTab, portfolios]);

  console.log("portfolio", portfolios);

  const handleSave = async () => {
    const body = {
      id: activePortfolio?.id,
      body: {
        name: title,
        blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
      },
    };
    try {
      const response = await updatePortfolio(body);
      console.log("update portfolio", response);
      setToast({
        status: "success",
        message: "portfolio updated successfully",
      });
    } catch (error) {
      setToast({ status: "success", message: "error updating portfolio" });
      console.log(error);
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  const handleDeleteTab = async (tabIndex) => {
    try {
      let tabItem = portfolios?.portfolios[tabIndex];
      const response = await deletePortfolio(tabItem).unwrap();
      setToast({
        status: "success",
        message: "portfolio deleted successfully",
      });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete portfolio" });
      console.log(error);
    }
  };

  return (
    <Box>
      {/* dialogs */}
      <AddPortfolioModal
        setToast={setToast}
        open={openAddSector}
        handleClose={() => setOpenAddSector(false)}
      />
      <CustomToast taost={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomAppBar>
        <Typography variant="pageHeading">Portfolio</Typography>
        <CustomLoadingButton loading={isUpdatingPortfolio} onClick={handleSave}>
          Save
        </CustomLoadingButton>{" "}
      </CustomAppBar>

      <Box className="page-body">
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={3}>
            <CustomInnerSideNav
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleDelete={(tabIndex) =>
                setConfirmation({
                  message:
                    "are you sure you want to delete this sector and its content?!",
                  action: () => handleDeleteTab(tabIndex),
                })
              }
            >
              <StyledWhiteButton
                startIcon={<AddCircle />}
                onClick={() => setOpenAddSector(true)}
              >
                Add Sector
              </StyledWhiteButton>
            </CustomInnerSideNav>
          </Grid>
          <Grid item xs={9}>
            {isLoadingSectors ? (
              <CustomLoadingCircles />
            ) : (
              <>
                <Box
                  sx={{
                    backgroundColor: "grey.light",
                    marginBottom: "16px",
                    padding: 2,
                    borderRadius: "8px",
                  }}
                >
                  <FormControl fullWidth>
                    <Typography variant="inputLabel" sx={{ mb: 1 }}>
                      Title
                    </Typography>
                    <StyledTextField
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>
                </Box>
                <CustomBlocksEditor
                  blocks={blocks}
                  setBlocks={setBlocks}
                  deleteBlock={handleDeleteBlock}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Portfolio;
