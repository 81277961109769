import { Box, FormControl, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomModal from "../../custom/CustomModal";
import { StyledTextField } from "../../custom/styled/styledInputs";
import { useCreateSegmentMutation } from "./segmentApiSlice";
import CustomLoadingButton from "../../custom/CustomLoadingButton";

function AddSegmentModal({ openAddSegment, handleClose, setToast }) {
  const [name, setName] = useState("");
  const [createSegment, { isLoading: isCreatingSegment }] =
    useCreateSegmentMutation();

  const handleSubmit = async () => {
    try {
      if (!name) {
        return setToast({ status: "error", message: "name cannot be empty" });
      }
      const body = {
        name,
        blocks: [],
      };
      const response = await createSegment(body).unwrap();
      setToast({
        status: "success",
        message: response.message || "Segment created succesfully",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      setToast({ status: "error", message: "error creating segment" });
    }
  };

  return (
    <CustomModal open={openAddSegment} handleClose={handleClose}>
      <Box
        sx={{
          my: 3,
          mx: 8,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          gap: 5,
        }}
      >
        <FormControl>
          <Typography variant="inputLabel">Segment Name</Typography>
          <StyledTextField
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <CustomLoadingButton loading={isCreatingSegment} onClick={handleSubmit}>
          Add
        </CustomLoadingButton>
      </Box>
    </CustomModal>
  );
}

export default AddSegmentModal;
