import { Paper, styled } from "@mui/material";

const StyledDashboardCard = styled(Paper)(({ theme }) => ({
  padding: "8px 8px",
  borderRadius: "10px",
  boxShadow: "none",
  color: `${theme.palette.grey.dark}`,
  "& .card-title-small": {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
  },
  "& .card-title-big": {
    fontWeight: 400,
    fontSize: "26px",
    lineHeight: "39px",
  },
  "& .card-sub-title": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .center-text": {
    textAlign: "center",
  },
  "& .divider": {
    width: "80%",
    margin: "6px auto 8px auto",
  },
  "& .num-count-large": {
    fontWeight: 600,
    fontSize: "43px",
    lineHeight: "64px",
    color: theme.palette.primary.main,
  },
  "& .num-count-small": {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "39px",
    color: theme.palette.primary.main,
  },

  "& .percent-box": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .rise-icon": {
      color: " #05812F",
      fontSize: "34px",
    },
    "& .drop-icon": {
      color: "#FF0000",
      fontSize: "34px",
    },
    "& .text": {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
  },

  "& .flex-spaceBetween": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  "& .top5-item": {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: "4px 8px",
    mb: 2,
    "& .flex": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    "& .title": {
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "42px",
    },
  },
}));

export { StyledDashboardCard };
