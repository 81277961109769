import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function CustomTextEditor({ onChange, value, height }) {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      onEditorChange={onChange}
      value={value}
      init={{
        height: height || 200,
        menubar: false,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "table",
          "code",
          "help",
          "wordcount",
          "quickbars",
        ],
        toolbar:
          "undo redo|bold italic underline|" +
          "formatselect forecolor removeformat fontsizeselect|" +
          "aligncenter alignjustify alignleft alignright|" +
          "bullist numlist link|fullscreen help|" +
          "h1 h2 h3 h4 h5 h6",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
}
