import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, MenuItem, Typography } from "@mui/material";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomBlocksEditor from "../../custom/CustomBlocksEditor";
import {
  StyledCreatableSingleSelect,
  StyledSelect,
  StyledTextField,
} from "../../custom/styled/styledInputs";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  useCreateReferenceMutation,
  useDeleteReferenceBlockMutation,
  useUpdateReferenceMutation,
} from "./referenceApiSlice";
import { useAddMediaMutation } from "../media-library/mediaApiSlice";
import {
  useCreatePortfolioMutation,
  useGetAllPortfolioQuery,
} from "../portfolio/portfolioApiSlice";
import CustomLoadingButton from "../../custom/CustomLoadingButton";
import ImageKit from "../../../helpers/ImageKit";

function CreateReference({
  handleCloseEditor,
  setToast,
  setConfirmation,
  activeReference,
}) {
  const [blocks, setBlocks] = useState([]);
  const [title, setTitle] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [location, setLocation] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { label: "select status", value: "" },
    { label: "Ongoing", value: "Ongoing" },
    { label: "Completed", value: "Completed" },
  ]);

  const { data: portfolios } = useGetAllPortfolioQuery();
  const [createReference, { isLoading: isCreatingReference }] =
    useCreateReferenceMutation();
  const [updateReference, { isLoading: isUpdatingNews }] =
    useUpdateReferenceMutation();
  const [uploadMedia, { isLoading: isUploadingImage }] = useAddMediaMutation();
  const [deleteBlock, { isLoading: isDeletingBlock }] =
    useDeleteReferenceBlockMutation();
  const [createSector, { isLoading: isCreatingSector }] =
    useCreatePortfolioMutation();

  useEffect(() => {
    //fill up initial state for update
    if (activeReference) {
      setTitle(activeReference.title || "");
      setBlocks(
        activeReference?.blocks?.map((block) =>
          block.type === "image"
            ? // make the media  array editable
              { ...block, media: block.media?.map((media) => media) }
            : block
        ) || []
      );
      setSelectedStatus(
        { label: activeReference.status, value: activeReference.status } || ""
      );
      // setStatusOptions((prev) =>
      //   //add selected status to options array if it doesnt exist
      //   prev.includes((status) => status.value === activeReference.status)
      //     ? [...prev]
      //     : [
      //         ...prev,
      //         { label: activeReference.status, value: activeReference.status },
      //       ]
      // );
      setLocation(activeReference.location || "");
      setSelectedSector(
        {
          label: portfolios?.portfolios?.find(
            (portfolio) => portfolio.id === activeReference.portfolio_id
          )?.name,
          value: activeReference.portfolio_id,
        } || ""
      );
      setIsFeatured(activeReference.is_featured || false);
    }
  }, [activeReference]);

  const handleSave = async () => {
    if (!title) {
      return setToast({ status: "error", message: "Title is required" });
    }

    const body = {
      title,
      featured_image: featuredImage,
      location: location,
      status: selectedStatus?.value || "",
      portfolio_id: selectedSector?.value || "",
      is_featured: isFeatured,
      blocks: blocks?.map((item, i) => ({ ...item, position: i + 1 })),
    };

    if (!selectedSector.__isNew__) {
      if (activeReference) {
        handleUpdateReference(body);
      } else {
        handleCreateReferece(body);
      }
    } else {
      console.log("creating sector", selectedSector);
      let sector_id = await handleCreateSector(selectedSector.value);
      if (activeReference) {
        handleUpdateReference({ ...body, portfolio_id: sector_id });
      } else {
        handleCreateReferece({ ...body, portfolio_id: sector_id });
      }
    }
  };

  const handleCreateReferece = async (body) => {
    try {
      const response = await createReference(body).unwrap();
      handleCloseEditor();
      setToast({
        status: "success",
        message: "Reference created successfully",
      });
    } catch (error) {
      console.log(error);
      setToast({
        status: "error",
        message: "Unable to create reference",
      });
    }
  };

  const handleUpdateReference = async (body) => {
    try {
      const response = await updateReference({
        id: activeReference.id,
        body,
      }).unwrap();
      handleCloseEditor();
      setToast({
        status: "success",
        message: "Reference updated successfully",
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      setToast({
        status: "error",
        message: "Unable to update reference",
      });
    }
  };

  const handleDeleteBlock = async (block) => {
    try {
      const response = await deleteBlock(block).unwrap();
      setToast({ status: "success", message: "Block deleted successfully" });
      if (response.message) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  const handleImagePickerChange = function (e) {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      const validFormats = [
        "image/webp",
        "image/png",
        "image/jpeg",
        "image/png",
      ];

      const isValid = validFormats.includes(file.type);
      if (!isValid) {
        return alert("invalid file format");
      } // end of validation
      const data = new FormData();
      data.append("file", file);
      uploadFile(data);
    }
  };

  const uploadFile = async (data) => {
    try {
      const response = await uploadMedia(data).unwrap();
      setToast({ status: "success", message: "Media uploaded successfully" });
      setFeaturedImage(response?.media[0]?.url);
    } catch (error) {
      setToast({ status: "error", message: "Failed to delete block" });
      console.log(error);
    }
  };

  const handleCreateSector = async (name) => {
    try {
      if (!name) {
        return setToast({ status: "error", message: "name cannot be empty" });
      }
      const body = {
        name,
        blocks: [],
      };
      const response = await createSector(body).unwrap();
      setToast({
        status: "success",
        message: response.message || "Sector created succesfully",
      });
      return response?.portfolio?.id;
    } catch (error) {
      console.log(error);
      setToast({ status: "error", message: "error creating sector" });
    }
  };

  return (
    <Box>
      <CustomAppBar>
        <Typography variant="pageHeading" onClick={handleCloseEditor}>
          References
        </Typography>
        <CustomLoadingButton
          loading={isCreatingReference || isUpdatingNews || isCreatingSector}
          onClick={handleSave}
        >
          {activeReference ? "update" : "Save"}
        </CustomLoadingButton>
      </CustomAppBar>
      <Box className="page-body">
        <Box
          sx={{
            color: "grey.dark",
            backgroundColor: "grey.light",
            display: "flex",
            alignItems: "center",
            width: "max-content",
            padding: "8px 16px 8px 8px",
            borderRadius: "10px",
            marginBottom: "16px",
            cursor: "pointer",
          }}
          onClick={() =>
            setConfirmation({
              message: "you will lose any unsaved changes, continue?",
              action: handleCloseEditor,
            })
          }
        >
          <NavigateBeforeIcon />
          <Typography>Back</Typography>
        </Box>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={3}
        >
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: "grey.light",
                marginBottom: "16px",
                padding: 2,
                borderRadius: "8px",
              }}
            >
              <FormControl fullWidth>
                <Typography variant="inputLabel" sx={{ mb: 1 }}>
                  Title
                </Typography>
                <StyledTextField
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>
            </Box>
            <CustomBlocksEditor
              blocks={blocks}
              setBlocks={setBlocks}
              deleteBlock={handleDeleteBlock}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                "& .hide": { display: "none" },
              }}
            >
              <FormControl fullWidth>
                <Typography variant="inputLabel">Featured Image</Typography>
                <StyledTextField
                  type="file"
                  onChange={handleImagePickerChange}
                />
                {isUploadingImage ? (
                  <p>uploading...</p>
                ) : featuredImage ? (
                  <img
                    alt="featured-img"
                    src={ImageKit({
                      url: featuredImage,
                      thumbnail: true,
                    })}
                    height={70}
                    width={70}
                    style={{ marginTop: "4px" }}
                  />
                ) : (
                  ""
                )}
              </FormControl>

              <FormControl fullWidth>
                <Typography variant="inputLabel">Sector</Typography>
                <StyledCreatableSingleSelect
                  onChange={(values) => setSelectedSector(values)}
                  value={selectedSector}
                  options={portfolios?.portfolios?.map((sector) => ({
                    label: sector.name,
                    value: sector.id,
                  }))}
                />
              </FormControl>

              <FormControl fullWidth>
                <Typography variant="inputLabel">Status</Typography>
                <StyledCreatableSingleSelect
                  onChange={(values) => setSelectedStatus(values)}
                  value={selectedStatus}
                  options={statusOptions}
                />
              </FormControl>

              <FormControl fullWidth>
                <Typography variant="inputLabel">Location</Typography>
                <StyledTextField
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </FormControl>

              <FormControl fullWidth>
                <Typography variant="inputLabel">Featuring</Typography>
                <StyledSelect
                  value={isFeatured}
                  onChange={(e) => setIsFeatured(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value={true}>Featured</MenuItem>
                  <MenuItem value={false}>Not Featured</MenuItem>
                </StyledSelect>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CreateReference;
