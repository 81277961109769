import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { StyledGreyButton } from "../../custom/styled/styledButtons";
import { useAddMediaMutation } from "./mediaApiSlice";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";

function UploadDialog({ open, setOpen, setToast }) {
  const [dragActive, setDragActive] = useState(false);
  const [addMedia, { isLoading: uploadingMedia }] = useAddMediaMutation();

  const imageButtonRef = useRef(null);

  const handleClose = () => {
    // close modal
    setOpen(false);
  };

  const uploadFile = async (data) => {
    try {
      const response = await addMedia(data).unwrap();
      setToast({ status: "success", message: "Media uploaded successfully" });
      handleClose();
    } catch (error) {
      setToast({ status: "error", message: "Failed to upload media" });
      console.log(error);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    if (e.type === "dragover" || e.type === "dragenter") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let file = e.dataTransfer.files[0];
      const data = new FormData();
      data.append("file", file);
      uploadFile(data);
    }
  };

  const handleImagePickerChange = function (e) {
    // if (e.target.files && e.target.files[0]) {
    //   let file = e.target.files[0];
    //   const data = new FormData();
    //   data.append("file", file);

    //   // uploadFile(data);
    // }
    let selectedFiles;
    const data = new FormData();
    if (e.target.files) {
      selectedFiles = [...e.target.files];
      selectedFiles?.forEach((file) => {
        data.append("file", file);
      });
      uploadFile(data);
    }
  };

  console.log("uploading...", uploadingMedia);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ borderRadius: 0 }}>
      <Box
        sx={{
          minWidth: "200px",
          backgroundColor: "grey.light",
          "& .close": {
            display: "flex",
            justifyContent: "flex-end",
          },
        }}
      >
        <Box className="close">
          <Close
            onClick={handleClose}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          className="body"
          id="drop_zone"
          onDrop={handleDragEnd}
          onDragOver={handleDrag}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          sx={{
            backgroundColor: "#fff",
            textAlign: "center",
            margin: "20px 40px 40px 40px",
            padding: "50px",
            border: "1px dashed",
            borderColor: dragActive ? "green" : "primary.main",
          }}
        >
          {dragActive ? (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "28px",
                lineHeight: "42px",
                color: "grey.dark",
              }}
            >
              Drop files here
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "28px",
                  lineHeight: "42px",
                  color: "grey.dark",
                }}
              >
                Drop files here
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "28px",
                  lineHeight: "42px",
                  color: "grey.dark",
                  margin: "20px auto",
                }}
              >
                OR
              </Typography>
              <StyledGreyButton
                onClick={() => imageButtonRef?.current?.click()}
                disabled={uploadingMedia}
              >
                {uploadingMedia ? (
                  <>
                    Uploading
                    <CustomLoadingCircles
                      height="20px"
                      width="20px"
                      color="rgba(0, 0, 0, 0.32)"
                    />
                  </>
                ) : (
                  "Select file"
                )}
              </StyledGreyButton>
              <input
                style={{ display: "none" }}
                type="file"
                className="hide"
                ref={imageButtonRef}
                onChange={handleImagePickerChange}
                multiple
                accept="image/*,.pdf,video/*"
              />
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default UploadDialog;
