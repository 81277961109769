import { ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/Layout";
import {
  CareerOpenings,
  CompanyProfile,
  Dashboard,
  Login,
  MediaLibrary,
  News,
  Portfolio,
  References,
  Segment,
  Pages,
  Meta,
} from "./components/pages";
import { useGetProfileMutation } from "./components/pages/auth/authApiSlice";
import {
  selectCurrentUser,
  setCredentials,
} from "./components/pages/auth/authSlice";
import { theme } from "./theme";

const NoNav = () => (
  <>
    <Outlet />
  </>
);

const ProtectedRoute = ({ isAuth, children }) => {
  //middleware component for protected routes
  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppRoutes = () => {
  // this component handle all routings
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const [getProfile] = useGetProfileMutation();

  useEffect(() => {
    //check saved user in local storage
    const credentials = localStorage.getItem("credentials");
    if (credentials) {
      dispatch(setCredentials(JSON.parse(credentials)));
    }
  }, []);

  useEffect(() => {
    if (!!user && location.pathname === "/login") {
      navigate("/");
    }
    getProfile();
  }, [location, user]);

  return (
    <Routes>
      <Route element={<NoNav />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route
        element={
          <ProtectedRoute isAuth={!!user}>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/media-library" element={<MediaLibrary />} />
        <Route path="/news" element={<News />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/career-openings" element={<CareerOpenings />} />
        <Route path="/segment" element={<Segment />} />
        <Route path="/references" element={<References />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/meta" element={<Meta />} />
      </Route>
    </Routes>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;
