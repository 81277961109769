import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["News"],
});

const newsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => `/news`,
      providesTags: ["News"],
    }),
    createNews: builder.mutation({
      query: (values) => ({
        url: "/news",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["News"],
    }),
    updateNews: builder.mutation({
      query: (values) => ({
        url: `/news/${values.id}`,
        method: "PUT",
        body: values.body,
      }),
      invalidatesTags: ["News"],
    }),
    deleteNews: builder.mutation({
      query: (value) => ({
        url: `/news/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["News"],
    }),
    deleteNewsBlock: builder.mutation({
      query: (value) => ({
        url: `/news/block/${value.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["News"],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
  useDeleteNewsBlockMutation,
} = newsApiSlice;
