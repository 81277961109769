import { MoreHoriz, MoreVert } from "@mui/icons-material";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import CustomAppBar from "../../custom/CustomAppBar";
import CustomConfirmation from "../../custom/CustomConfirmation";
import CustomToast from "../../custom/CustomToast";
import { StyledFilledButton } from "../../custom/styled/styledButtons";
import {
  useDeleteCareerMutation,
  useGetAllCareerQuery,
  useGetCareerQuery,
  useUpdateCareerMutation,
} from "./careerApiSlice";
import CreateCareerOpening from "./CreateCareerOpening";
import Truncate from "react-truncate-html";
import CustomLoadingCircles from "../../custom/CustomLoadingCircles";

function CareerOpenings() {
  const [openAddOpening, setOpenAddOpening] = useState(false);
  const [toast, setToast] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  // menu states to update team member
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetCareer, setTargetCareer] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event, career) => {
    setAnchorEl(event.currentTarget);
    setTargetCareer(career);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTargetCareer(null);
    setOpenAddOpening(false);
  };

  const { data: careers, isLoading: isLoadingCareer } = useGetAllCareerQuery();
  const [deleteCareer, { isLoading: isDeletingCareer }] =
    useDeleteCareerMutation();
  const [updateCareer, { isLoading: isUpdatingCareer }] =
    useUpdateCareerMutation();

  const handleDeleteCareer = async (career) => {
    try {
      const response = await deleteCareer(career).unwrap();
      setToast({
        status: "success",
        message: response.message || "career opening deleted successfully",
      });
      handleClose();
      console.log(response);
    } catch (error) {
      setToast({
        status: "error",
        message: error.data.message || "Error career",
      });
      console.log(error);
    }
  };

  const handleToggleCareerStatus = async (data) => {
    try {
      handleClose();
      const body = {
        body: { ...data, is_open: !data.is_open },
        id: targetCareer?.id,
      };
      const response = await updateCareer(body).unwrap();
      setToast({
        status: "success",
        message: response.message || "career updated successfully",
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      setToast({
        status: "error",
        message: error.data.message || "Error updating Career",
      });
    }
  };

  return (
    <Box>
      {/* modals */}
      <CreateCareerOpening
        open={openAddOpening}
        handleClose={handleClose}
        setToast={setToast}
        targetCareer={targetCareer}
      />
      <CustomToast toast={toast} setToast={setToast} />
      <CustomConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CustomAppBar>
        <Typography variant="pageHeading">Career Openings</Typography>
        <StyledFilledButton onClick={() => setOpenAddOpening(true)}>
          Create
        </StyledFilledButton>
      </CustomAppBar>
      <Box className="page-body">
        {isLoadingCareer ? (
          <CustomLoadingCircles />
        ) : (
          careers?.careers?.map((career) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                backgroundColor: "grey.light",
                borderRadius: "10px",
                padding: "8px",
                mb: 2,
                "& .flex-space-between": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },

                "& .header": {
                  width: "100%",
                  flexWrap: "wrap",
                  "& .title": {
                    fontWeight: 700,
                    fontSize: "28px",
                    lineHeight: "42px",
                    "& > span": {
                      fontWeight: 400,
                      fontSize: "24px",
                    },
                  },
                },

                "& .content": {
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "30px",
                  // flexWrap: "nowrap",
                },

                "& .status": {
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "30px",
                  "& > .green": {
                    fontWeight: "550",
                    color: "green",
                  },
                  "& > .red": {
                    fontWeight: "500",
                    color: "red",
                  },
                },
              }}
            >
              <Box className="header flex-space-between">
                <Typography className="title">
                  {career.position} -<span> {career.segment?.name}</span>
                </Typography>
                <Typography className="status">
                  Status:{" "}
                  {career.is_open ? (
                    <span className="green">Open</span>
                  ) : (
                    <span className="red">Closed</span>
                  )}
                </Typography>
              </Box>
              <Box className="content flex-space-between">
                <div sx={{ height: "100%" }}>
                  <Truncate
                    responsive={true}
                    breakWord={true}
                    lines={4}
                    dangerouslySetInnerHTML={{
                      __html: `${career.description}`,
                    }}
                  />
                </div>
                <MoreVert
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => handleClickMenu(e, career)}
                />
              </Box>
              <Box className="status flex-space-between">
                <Typography className="status">
                  Closing:{" "}
                  <span className="red">
                    {format(parseISO(career.closing_date), "dd MMM, yyyy")}
                  </span>
                </Typography>
              </Box>
            </Box>
          ))
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setTargetCareer(targetCareer);
              setOpenAddOpening(true);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() =>
              setConfirmation({
                message: "Are you sure you want to delete this opening?",
                action: () => {
                  handleDeleteCareer(targetCareer);
                },
              })
            }
          >
            Delete
          </MenuItem>
          <MenuItem onClick={() => handleToggleCareerStatus(targetCareer)}>
            {targetCareer?.is_open ? "Close" : "Open"}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default CareerOpenings;
